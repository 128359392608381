// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import { differenceInMinutes } from "date-fns";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkAllowedToPublish } from "../../../../../../../export/shift-scheduler";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import {
	getWorkersCountByShift,
	resetWorkersCountByShift,
} from "../../../../../jobs/store/jobs-slice";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { reducer } from "../utils";

interface DraftShift {
	createdByName: string;
	startDate: string;
	endDate: string;
	numberOfWorkersNeeded: number;
	id: number;
	jobId: string;
	skills?: string[];
	startDateTime: Date | string;
	endDateTime: Date | string;
	shiftPurposeId: number | null;
}

interface Shift {
	jobId: string;
	startDateTime: Date | string;
	endDateTime: Date | string;
}

interface RowData {
	shiftTime: string;
	shiftDateTime: string;
	workersRequired: number;
	hoursScheduled: string;
	id: number;
	jobRole: any; // Adjust the type accordingly
	date: string;
	time: { start: string; end: string };
	createdByName: string;
	totalPublishedTime: number;
	shiftDurationInMinutes: number;
	restrictFlexHours: boolean;
	skills?: string[];
	shiftPurpose: string;
}

const useReviewPublishShiftDialog = () => {
	const [state, dispatchLocal] = useReducer(reducer, []);
	const dispatch = useDispatch();
	const [rows, setRows] = useState<RowData[]>([]);
	const [amISure, setAmISure] = useState(false);

	const restrictFlexHours = useSelector(
		(state: any) => state.user.userData.restrictFlexHours
	);

	const workerCountByShift = useSelector(
		(state: any) => state.manageJobs.getWorkersCountByShift.data
	);

	const workerCountByShiftStatus = useSelector(
		(state: any) => state.manageJobs.getWorkersCountByShift.status
	);

	const reviewPublishShiftIds = useSelector(
		(state: any) => state.supervisor.reviewPublishShiftDialog.shiftIds
	);

	const resetShiftCount = useCallback(() => {
		dispatch(resetWorkersCountByShift());
	}, [dispatch]);

	const {
		isWeekView,
		currentDate,
		shiftsData,
		filterJobRoles,
		checkAllowedToPublishData,
		filterAllWorkcenters,
		shiftPurposes,
	} = useSchedulerData();

	useEffect(() => {
		// get draft(unpublished) shifts
		if (shiftsData) {
			const rowData: RowData[] = [];
			const published = shiftsData.filter(
				(shift: { isPublished: boolean }) => shift.isPublished
			);

			const publishedJobMinutesInWeek: { [jobId: string]: number } =
				published.reduce((acc: { [jobId: string]: number }, shift: Shift) => {
					const job = shift.jobId;
					const minutes = differenceInMinutes(
						new Date(shift.endDateTime),
						new Date(shift.startDateTime)
					);
					acc[job] = acc[job] ? acc[job] + minutes : minutes;
					return acc;
				}, {});

			const drafts = shiftsData.filter(
				(shift: { isPublished: boolean }) => shift.isPublished === false
			);

			drafts.forEach((shift: DraftShift) => {
				const createdByName = shift.createdByName;
				const shiftDateTime: string = shift?.startDate;
				const date = `${format(
					new Date(shift?.startDate),
					"EEE, MMMM do yyyy"
				)}`;
				const time = {
					start: `${format(new Date(shift?.startDate), "h:mm a")}`,
					end: `${format(new Date(shift?.endDate), "h:mm a")}`,
				};

				const shiftTime = `${format(
					new Date(shift?.startDate),
					"EEE, MMMM do yyyy h:mm a"
				)} - ${format(new Date(shift?.endDate), "h:mm a")}`;

				const workersRequired = shift.numberOfWorkersNeeded;

				const { hours, minutes } = calculateShiftHoursAndMinutes(
					shift?.startDate,
					shift?.endDate,
					workersRequired
				);

				const hoursScheduled = `${hours} hours ${minutes} mins`;

				const id = shift.id;
				const skills = shift?.skills;

				let currentJobRole = filterJobRoles.filter(
					(jobRole: { id: string }) => jobRole.id === shift.jobId
				);
				const shiftPurpose = shift?.shiftPurposeId
					? shiftPurposes[shift?.shiftPurposeId]?.name
					: "-";

				if (currentJobRole) {
					currentJobRole = currentJobRole[0];
					// const jobRole = currentJobRole.name;
					const jobRole = currentJobRole;
					const jobId = currentJobRole?.id;
					publishedJobMinutesInWeek[jobId] = publishedJobMinutesInWeek[jobId]
						? publishedJobMinutesInWeek[jobId] +
						  differenceInMinutes(
								new Date(shift.endDateTime),
								new Date(shift.startDateTime)
						  )
						: differenceInMinutes(
								new Date(shift.endDateTime),
								new Date(shift.startDateTime)
						  );
					const totalPublishedTime = publishedJobMinutesInWeek[jobId];
					const shiftDurationInMinutes = differenceInMinutes(
						new Date(shift.endDateTime),
						new Date(shift.startDateTime)
					);
					rowData.push({
						shiftTime,
						shiftDateTime,
						workersRequired,
						hoursScheduled,
						id,
						jobRole,
						date,
						time,
						createdByName,
						totalPublishedTime,
						shiftDurationInMinutes,
						restrictFlexHours,
						skills,
						shiftPurpose,
					});
				}
			});
			// filter out draft shifts which are in the past
			const now = new Date();
			const finalShifts = rowData.filter(
				(row: RowData) => new Date(row.shiftDateTime) > now
			);
			if(reviewPublishShiftIds.length > 0) {
				setRows(finalShifts.filter((row: RowData) => reviewPublishShiftIds.includes(row.id)));
				// setIamSure to true
				setAmISure(true);
			}
			else if (isWeekView) {
				setRows(finalShifts);
			} else {
				const currentDayShifts: RowData[] = finalShifts?.filter((row: RowData) =>
					isSameDay(new Date(row.shiftDateTime), new Date(currentDate))
				);
				setRows(currentDayShifts);
			}
		}
	}, [
		shiftsData,
		filterJobRoles,
		restrictFlexHours,
		isWeekView,
		currentDate,
		shiftPurposes,
		reviewPublishShiftIds,
	]);

	const [timeLeftByJobs, setTimeLeftByJobs] = useState({});
	useEffect(() => {
		const shiftsByJob = rows.filter((row) => row.jobRole);
		if (restrictFlexHours) {
			const obj: { [key: string]: string } = {};
			shiftsByJob.forEach((row) => {
				obj[row.id] = row.jobRole.id;
			});
			const timeRemainingByJob: { [key: string]: number } = {};
			shiftsByJob.forEach((row) => {
				if (!timeRemainingByJob[row.jobRole.id])
					timeRemainingByJob[row.jobRole.id] = 999999;
			});
			shiftsByJob.forEach((row) => {
				if (checkAllowedToPublishData[row.id])
					timeRemainingByJob[row.jobRole.id] =
						checkAllowedToPublishData[row.id].timeRemaining;
			});
			setTimeLeftByJobs(timeRemainingByJob);
		}
	}, [rows, checkAllowedToPublishData, state, restrictFlexHours]);

	useEffect(() => {
		if (restrictFlexHours && state.length > 0) {
			const filteredRows: RowData[] = rows.filter(
				(row: any) => state.includes(row.id) && row.jobRole
			);
			const temp: { [key: string]: number } = {};
			filteredRows.forEach((row) => {
				if (temp[row.jobRole.id]) {
					temp[row.jobRole.id] =
						temp[row.jobRole.id] +
						(row.shiftDurationInMinutes / 60) * Number(row.workersRequired);
				} else {
					temp[row.jobRole.id] =
						(row.shiftDurationInMinutes / 60) * Number(row.workersRequired);
				}
			});
			setTimeLeftByJobs((pre: { [key: string]: number }) => {
				const result: { [key: string]: number } = {};
				for (const key in pre) {
					if (key in temp && key in pre) result[key] = pre[key] - temp[key];
					else result[key] = pre[key];
				}
				return result;
			});
		}
	}, [state, rows, restrictFlexHours]);

	useEffect(() => {
		if (restrictFlexHours) {
			const arr: string[] = [];
			rows.forEach((row: any) => {
				arr.push(row.id);
			});
			// make api call for shifts check
			dispatch(checkAllowedToPublish(arr));
		}
	}, [rows, dispatch, restrictFlexHours]);

	const handleShiftSelection = useCallback((e: number[]) => {
		const newSelection = e.filter((id) => !state.includes(id));
		const shift = shiftsData.find(
			(shift: { id: number }) => shift.id === newSelection[0]
		);
		if (shift) {
			const locationId = filterAllWorkcenters.find(
				(workcenter: { id: string }) => workcenter.id === shift.workCenterId
			).locationId;
			const payload = {
				startDateTime: shift.startDateTime,
				endDateTime: shift.endDateTime,
				jobId: shift.skills.length > 0 ? null : shift.jobId,
				locationId: locationId,
				shiftSkills: shift.skills,
				shiftId: shift.id,
			};
			dispatch(getWorkersCountByShift(payload));
		}
		dispatchLocal({ type: "setShiftsForPublish", payload: e });
	}, [state, shiftsData, filterAllWorkcenters, dispatch]);

	// First useEffect: Check worker count
	useEffect(() => {
		if (reviewPublishShiftIds.length === 1 && shiftsData?.length > 0) {
			const shift = shiftsData?.find(
				(shift: { id: number }) => shift.id === reviewPublishShiftIds[0]
			);
						
			if (shift) {
				const locationId = filterAllWorkcenters.find(
					(workcenter: { id: string }) => workcenter.id === shift.workCenterId
				)?.locationId;

				const payload = {
					startDateTime: shift.startDateTime,
					endDateTime: shift.endDateTime,
					jobId: shift.skills.length > 0 ? null : shift.jobId,
					locationId: locationId,
					shiftSkills: shift.skills,
					shiftId: shift.id,
				};
				
				dispatch(getWorkersCountByShift(payload));
			}
		}
	}, [reviewPublishShiftIds, shiftsData, filterAllWorkcenters, dispatch]);

	// Second useEffect: Handle shift selection after worker count is confirmed
	useEffect(() => {
		if (
			reviewPublishShiftIds.length === 1 && 
			workerCountByShiftStatus === "fulfilled" && 
			shiftsData?.length > 0
		) {
			const shiftId = reviewPublishShiftIds[0];
			if (!workerCountByShift[shiftId] || workerCountByShift[shiftId] > 0) {
				dispatchLocal({ type: "setShiftsForPublish", payload: reviewPublishShiftIds });
			}
		}
	}, [
		reviewPublishShiftIds, 
		workerCountByShift, 
		workerCountByShiftStatus, 
		shiftsData
	]);

	return [
		{
			state,
			rows,
			amISure,
			timeLeftByJobs,
			workerCountByShift,
			workerCountByShiftStatus,
			reviewPublishShiftIds,
		},
		{ handleShiftSelection, resetShiftCount, setAmISure },
	];
};

export default useReviewPublishShiftDialog;
