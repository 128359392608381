import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export default function Footer() {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        color: "black",
        borderTop: 1,
        borderTopWidth: 1,
        borderTopColor: "#E0E0E0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          color: "rgba(0, 0, 0, 0.6)",
          paddingLeft: "24px",
        }}
      >
        © 2025 Wilya. All rights reserved
      </Typography>
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          color: "background: rgba(0, 0, 0, 0.87)",
          paddingRight: "24px",
          cursor: "pointer",
        }}
        onClick={() => window.open("https://wilya.com/privacy-policy/","_blank")}
      >
        Privacy Policy
      </Typography>
    </Box>
  );
}
