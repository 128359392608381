import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkSharpIcon from "@mui/icons-material/LinkSharp";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";

// import { removeDuplicatesById } from "../../../../../utils/helpers";
import useShiftAllocationConfigController from "./controllers/use-shift-allocation-config-controller";
import ConfigurationDialog from "./dialogs/configuration-dialog";
import SelectJobsDialog from "./dialogs/select-jobs";
import SeniorityBasedConfigurationDialog from "./dialogs/seniority-based-configuration";

// const FASTEST ={
// 	tooltip: "Shift allocation decided by the order workers mark interest on shift.",
// 	dialogTitle: "Fastest Worker based allocation - configuration",
// 	voluntaryRules: [],
// 	ForcedOTRules: [],
// }
// const SENIORITY ={
// 	tooltip: "Shift assigned from most experienced worker to least experienced.",
// 	dialogTitle: "Seniority based allocation - configuration",
// 	voluntaryRules: [],
// 	ForcedOTRules: [],
// }
// const WORKCENTER ={
// 	tooltip: "Shifts are assigned to workers in your team first, then to other teams and so on. ",
// 	dialogTitle: "Workcenter based allocation - configuration",
// 	voluntaryRules: [],
// 	ForcedOTRules: [],
// }

// const LOCATION ={
// 	tooltip: "Shifts are first assigned to Flex workers in your team first, then to other FT workers teams and so on.",
// 	dialogTitle: "Worker Type and Location based allocation - configuration",
// 	voluntaryRules: [],
// 	ForcedOTRules: [],
// }
// const PRIMARYJOB ={
// 	tooltip: "Workers with their Primary Job that matches the Shift requirement are preferred.",
// 	dialogTitle: "Primary Job based Shift Allocation - Configuration",
// 	voluntaryRules: [],
// 	ForcedOTRules: [],
// }

const ALLOCATION_TYPE_DETAILS: Record<string, AllocationTypeProps> = {
	FASTEST: {
		id: undefined,
		dialogTitle: "Fastest Worker based allocation - configuration",
		voluntaryRules: [],
		forcedOTRules: [],
		tooltip:
			"Shift allocation decided by the order workers mark interest on shift.",
		type: "FASTEST",
	},
	SENIORITY: {
		id: undefined,
		dialogTitle: "Seniority based allocation - configuration",
		voluntaryRules: [],
		forcedOTRules: [],
		tooltip:
			"Shift assigned from most experienced worker to least experienced.",
		type: "SENIORITY"
	},
	WORKCENTER: {
		id: undefined,
		dialogTitle: "Workcenter based allocation - configuration",
		voluntaryRules: [
			"Worker Workcenter is Shift Workcenter (sorted by Lowest Overtime and Highest Seniority)",
			"Worker Location is Shift Location (sorted by Lowest Overtime and Highest Seniority)",
		],
		forcedOTRules: [
			"Worker Workcenter = Shift Workcenter, sorted by Lowest Seniority, Lowest Overtime",
			"Worker Location = Shift Location, sorted by Lowest Seniority, Lowest Overtime",
		],
		tooltip:
			"Shifts are assigned to workers in your team first, then to other teams and so on.",
		type: "WORKCENTER"
	},
	LOCATION: {
		id: undefined,
		dialogTitle: "Worker Type and Location based allocation - configuration",
		voluntaryRules: [
			"Flex Worker and Worker Location is Shift Location and Location cluster (sorted by Fastest Acceptance)",
			"Full time Worker and Worker Location is Shift Location (sorted by Highest Seniority)",
			"Full time Worker and Worker Location is in Shift Location Cluster (sorted by Highest Seniority)"
		],
		forcedOTRules: [
			"Full time Worker and Worker location is Shift location (sorted by Lowest Seniority)",
			"Full time Worker and Worker location is in Shift location cluster (sorted by Lowest Seniority)"
		],
		tooltip:
			"Shifts are first assigned to Flex workers in your team first, then to other FT workers teams and so on.",
		type: "LOCATION"
	},
	PRIMARYJOB: {
		id: undefined,
		dialogTitle: "Primary Job based Shift Allocation - Configuration",
		voluntaryRules: [
			"Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)",
			"Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)",
			"Worker’s Workcenter is NOT ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Lowest EmployeeID)",
		],
		forcedOTRules: [
			"Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)",
			"Worker’s Workcenter is ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)",
			"Worker’s Workcenter is NOT ShiftWorkcenter AND Worker’s Primary Job is NOT Shift Job Role (sorted by Lowest Overtime and Highest EmployeeID)",
		],
		tooltip:
			"Workers with their Primary Job that matches the Shift requirement are preferred.",
		type: "PRIMARYJOB"
	},
};

interface ShiftAllocationRulesProps {
	locationData: any;
	setLocationData: any;
	creatingNewLocation: boolean;
}

interface AllocationTypeProps {
	id: number | undefined;
	dialogTitle: string;
	voluntaryRules: string[];
	forcedOTRules: string[];
	tooltip: string;
	type: string;
}

const ShiftAllocationRules = ({
	locationData,
	setLocationData,
	creatingNewLocation,
}: ShiftAllocationRulesProps) => {
	const [
		{
			jobDialogOpen,
			seniorityDialogOpen,
			status,
			errorMessage,
			shiftAllocationRules,
			allJobsByLocation,
			allJobsByLocationObject,
			workcenterDialogOpen,
			locationDialogOpen,
			primaryJobDialogOpen,
			configurationDialogOpen,
			initialShiftAllocationRules,
			filteredJobsByLocation,
		},
		{
			handleDefaultChange,
			setJobDialogOpen,
			setShiftAllocationRules,
			handleJobRemove,
			openWorkcenterDialog,
			closeWorkcenterDialog,
			openSeniorityDialog,
			closeSeniorityDialog,
			openLocationDialog,
			closeLocationDialog,
			openPrimaryJobDialog,
			closePrimaryJobDialog,
			openConfigurationDialog,
			closeConfigurationDialog,
		},
	] = useShiftAllocationConfigController({
		locationData,
		setLocationData,
		creatingNewLocation,
	});

	const [allocationType, setAllocationType] =
		useState<AllocationTypeProps | null>(null);

	const SeniorityBasedId = shiftAllocationRules?.find(
		(rule: { code: string }) => rule.code === "SENIORITY"
	)?.shiftAllocationTypeId;

	const LocationBasedId = shiftAllocationRules?.find(
		(rule: { code: string }) => rule.code === "LOCATION"
	)?.shiftAllocationTypeId;

	const WorkcenterBasedId = shiftAllocationRules?.find(
		(rule: { code: string }) => rule.code === "WORKCENTER"
	)?.shiftAllocationTypeId;

	const PrimaryJobBasedId = shiftAllocationRules?.find(
		(rule: { code: string }) => rule.code === "PRIMARYJOB"
	)?.shiftAllocationTypeId;

	// const dialogTitles = {
	// 	[LocationBasedId]: "Location based allocation - configuration",
	// 	[PrimaryJobBasedId]: "Primary job based allocation - configuration",

	// }

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Shift Allocation Engine
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "14px",
						color: "rgba(0, 0, 0, 0.6)",
					}}
				>
					Configuration around how flex or overtime shifts are allocated to
					workers.
				</Typography>
			</Box>
			<Box mt={2}>
				<Box display='flex'>
					<Box width={300}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Rule
						</Typography>
					</Box>
					<Box width={120} display='flex' alignItems='center'>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Default
						</Typography>
						<Tooltip title='Shifts created by supervisor will follow this allocation rule.'>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>
					<Box width={600}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Associated job roles
						</Typography>
					</Box>
					<Box width={110}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Configuration
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box>
					{status === "pending" ? (
						<Box
							height={50}
							display='flex'
							justifyContent='center'
							alignItems='center'
						>
							<CircularProgress />
						</Box>
					) : status === "error" ? (
						<Typography>{errorMessage}</Typography>
					) : shiftAllocationRules?.length === 0 ? (
						<Box
							// height={50}
							display='flex'
							justifyContent='center'
							alignItems='center'
						>
							<Typography> No Rules Found</Typography>
						</Box>
					) : (
						shiftAllocationRules?.map(
							({
								name,
								isDefault,
								isConfigurable,
								jobIds,
								code,
								shiftAllocationTypeId,
							}) => (
								<Box
									key={shiftAllocationTypeId}
									display='flex'
									alignItems='center'
									minHeight={40}
								>
									<Box width={300} display='flex' alignItems='center'>
										<Typography width={250}>{name}</Typography>
										<Tooltip
											title={
												// code === "FASTEST"
												// 	? FASTEST.tooltip
												// 	: code === "SENIORITY"
												// 	? SENIORITY.tooltip
												// 	: code === "LOCATION"
												// 	? LOCATION.tooltip
												// 	: code === "PRIMARYJOB"
												// 	? PRIMARYJOB.tooltip
												// 	: WORKCENTER.tooltip
												ALLOCATION_TYPE_DETAILS[code].tooltip
											}
										>
											<InfoOutlinedIcon
												sx={{ marginLeft: "8px", fontSize: "18px" }}
											/>
										</Tooltip>
									</Box>
									<Box width={110}>
										<Radio
											checked={isDefault}
											onChange={(e) =>
												handleDefaultChange && handleDefaultChange(e, name)
											}
											value={shiftAllocationTypeId}
											name='radio-buttons'
											inputProps={{ "aria-label": "B" }}
										/>
									</Box>
									<Box width={600} display='flex' alignItems='center'>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "25px",
												minWidth: "25px",
												height: "25px",
												backgroundColor: !isDefault ? "#2f4d8b" : "#aeb9cf",
												borderRadius: "50%",
												cursor: !isDefault ? "pointer" : "default",

												marginRight: "8px",
											}}
											onClick={() =>
												!isDefault &&
												setJobDialogOpen &&
												setJobDialogOpen(shiftAllocationTypeId)
											}
										>
											<LinkSharpIcon
												sx={{ color: "white", fontSize: "20px" }}
											/>
										</Box>
										{jobIds && !isDefault && allJobsByLocationObject ? (
											<Stack
												direction='row'
												flexWrap='wrap'
												alignItems='flex-start'
												width={600}
											>
												{jobIds.split(",").map((job) => (
													<Chip
														key={job}
														label={allJobsByLocationObject[job]?.name ?? ""}
														onDelete={() =>
															handleJobRemove &&
															handleJobRemove(shiftAllocationTypeId, job)
														}
														sx={{ margin: "4px" }}
													/>
												))}
											</Stack>
										) : null}
									</Box>

									<Box>
										{isConfigurable ? (
											<EditIcon
												sx={{
													fontSize: "20px",
													marginLeft: "8px",
													cursor: "pointer",
												}}
												onClick={() => {
													// if (code === "SENIORITY") {
													// 	openSeniorityDialog && openSeniorityDialog();
													// } else {
													const allocationDetails =
														ALLOCATION_TYPE_DETAILS[code];
													if (allocationDetails) {
														setAllocationType({
															...allocationDetails,
															id: shiftAllocationTypeId, // Set the dynamic ID here
														});
														openConfigurationDialog &&
															openConfigurationDialog();
													}
													// }
													// else if (code === "LOCATION") {
													// 	// openLocationDialog && openLocationDialog();
													// 	// setAllocationType(LOCATION);
													// 	// openConfigurationDialog &&
													// 	// 	openConfigurationDialog();
													// } else if (code === "PRIMARYJOB") {
													// 	// openPrimaryJobDialog && openPrimaryJobDialog();
													// 	setAllocationType(PRIMARYJOB);
													// 	openConfigurationDialog &&
													// 		openConfigurationDialog();
													// } else {
													// 	// openWorkcenterDialog && openWorkcenterDialog();
													// 	setAllocationType(WORKCENTER);
													// 	openConfigurationDialog &&
													// 		openConfigurationDialog();
													// }
												}}
											/>
										) : null}
									</Box>
								</Box>
							)
						)
					)}
				</Box>
			</Box>
			<SelectJobsDialog
				open={jobDialogOpen !== -1}
				handleClose={() => setJobDialogOpen && setJobDialogOpen(-1)}
				allJobsByLocation={filteredJobsByLocation ?? []}
				allAssigned={true}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={jobDialogOpen}
				allJobsByLocationObject={allJobsByLocationObject}
				setLocationData={setLocationData}
				locationData={locationData}
			/>
			<SeniorityBasedConfigurationDialog
				open={!!seniorityDialogOpen}
				handleClose={() => closeSeniorityDialog && closeSeniorityDialog()}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={SeniorityBasedId}
				locationData={locationData}
				setLocationData={setLocationData}
			/>
			<ConfigurationDialog
				open={!!configurationDialogOpen}
				handleClose={() =>
					closeConfigurationDialog && closeConfigurationDialog()
				}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				// id={allocationType?.id}
				locationData={locationData}
				setLocationData={setLocationData}
				// title={allocationType?.dialogTitle ?? ""}
				allocationTypeDetails={allocationType}
				initialRules={initialShiftAllocationRules}
			/>
		</Box>
	);
};

export default ShiftAllocationRules;
