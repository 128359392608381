import { AlertTitle, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { resetWorkerRateLimitError } from "../store/web-worker-slice";


export default function WorkerSnackBar({}) {
  const { t } = useTranslation();
  const data = useSelector((state) => state?.worker?.workerRateLimitError);
  const snackbarVisisble = data.isErrorOccurred;
  const dispatch= useDispatch()

 const  handleClose = () => {
	dispatch(resetWorkerRateLimitError())
  }
  return (
    <Snackbar open={snackbarVisisble} autoHideDuration={6000} onClose={handleClose}
		anchorOrigin={{ vertical: "top", horizontal: "center" }}
	>
      <MuiAlert
        onClose={handleClose}
        severity="error"
        sx={{ width: "80%" }}
      >
		<AlertTitle>{t("rate_limit_error-title")}</AlertTitle>
		{t("rate_limit_error-message")}
      </MuiAlert>
    </Snackbar>
  );
}
