import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { differenceInMinutes, endOfDay, startOfDay } from "date-fns";

import axios from "../../../../js/utils/api-client";
import { setSupervisorRateLimitError } from "../../../features/user/user-slice";
import {
	Metrics,
	Shift,
	Worker,
	WorkerActivitySliceStateTypes,
} from "./worker-activity-slice-types";

const initialState: WorkerActivitySliceStateTypes = {
	startMonthDate: new Date(
		new Date().setMonth(new Date().getMonth() - 1)
	).toISOString(),
	endMonthDate: new Date().toISOString(),
	currentDate: startOfDay(new Date()).toISOString(),
	monthlyActivityReport: {
		status: "idle",
		errorMessage: "",
		allData: [],
		kpisData: [],
	},
	dailyActivityReport: {
		status: "idle",
		errorMessage: "",
		allData: [],
		kpisData: [],
	},
	selectedKPI: "ALL",
	monthlyKPISummaries: {
		"WORKERS WITH AT LEAST 1 SHIFT": 0,
		"WORKERS WITH NO SHIFTS": 0,
		"HOURS COMPLETED": 0,
		"NO CALL NO SHOWS": 0,
		ALL: 0,
	},
	selectedDayKPI: "ALL",
	dayKPISummaries: {
		"WORKERS WITH AT LEAST 1 SHIFT": 0,
		"WORKERS WITH NO SHIFTS": 0,
		"HOURS COMPLETED": 0,
		"NO CALL NO SHOWS": 0,
		ALL: 0,
	},
};

export const getMonthlyActivityReport = createAsyncThunk(
	"worker-activity/getMonthlyActivityReport",
	async (
		payload: { startDate: string; endDate: string },
		{ fulfillWithValue, rejectWithValue, dispatch }
	) => {
		const { startDate, endDate } = payload;

		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Reports/GetWorkerActivityReport?dateOfReportStart=${startDate}&dateOfReportEnd=${endDate}`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			}
			if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (
				error instanceof Error &&
				error.message === "Request failed with status code 404"
			)
				return rejectWithValue({ error: "No data found" });
			if (
				error &&
				typeof error === "object" &&
				"response" in error &&
				typeof error.response === "object" &&
				error.response !== null &&
				"data" in error.response
			)
				return rejectWithValue({ error: error.response.data });
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue({ error: "An unknown error occurred" });
		}
	}
);

export const getDailyActivityReport = createAsyncThunk(
	"worker-activity/getDailyActivityReport",
	async (payload: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
		const startDate = startOfDay(new Date(payload)).toISOString();
		const endDate = endOfDay(new Date(payload)).toISOString();

		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Reports/GetWorkerActivityReport?dateOfReportStart=${startDate}&dateOfReportEnd=${endDate}`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			}
			if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			const errorResponse = error?.response?.data;
			errorResponse.status = error?.response?.status;
			if (
				error instanceof Error &&
				error.message === "Request failed with status code 404"
			)
				return rejectWithValue({ error: "No data found" });
			if (
				error &&
				typeof error === "object" &&
				"response" in error &&
				typeof error.response === "object" &&
				error.response !== null &&
				"data" in error.response
			)
				return rejectWithValue({ error: error.response.data });
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue({ error: "An unknown error occurred" });
		}
	}
);

export const WorkerActivitySlice = createSlice({
	name: "workerActivity",
	initialState,
	reducers: {
		setSelectedKPI: (state, action) => {
			const allData = state.monthlyActivityReport.allData;

			const kpiFilters: { [key: string]: () => Worker[] } = {
				ALL: () => allData,
				"WORKERS WITH AT LEAST 1 SHIFT": () =>
					allData.filter((item) => item.shifts.length > 0),
				"WORKERS WITH NO SHIFTS": () =>
					allData.filter((item) => item.shifts.length === 0),
				"HOURS COMPLETED": () => {
					const workersWithShifts = allData.filter(
						(item) => item.shifts.length > 0
					);
					return workersWithShifts.map((item) => ({
						...item,
						shifts: item.shifts.filter(
							(shift) => shift.attendanceStatus.toLowerCase() !== "a"
						),
					}));
				},
				"NO CALL NO SHOWS": () => {
					const workersWithShifts = allData.filter(
						(item) =>
							item.shifts.length > 0 &&
							item.shifts.some(
								(shift) => shift.attendanceStatus.toLowerCase() === "a"
							)
					);
					return workersWithShifts.map((item) => ({
						...item,
						shifts: item.shifts.filter(
							(shift) => shift.attendanceStatus.toLowerCase() === "a"
						),
					}));
				},
			};

			// Apply the filter based on the selected KPI
			state.monthlyActivityReport.kpisData =
				kpiFilters[action.payload]?.() || allData;
			state.selectedKPI = action.payload;
		},
		setSelectedDayKPI: (state, action) => {
			const allData = state.dailyActivityReport.allData;

			const kpiFilters: { [key: string]: () => Worker[] } = {
				ALL: () => allData,
				"WORKERS WITH AT LEAST 1 SHIFT": () =>
					allData.filter((item) => item.shifts.length > 0),
				"WORKERS WITH NO SHIFTS": () =>
					allData.filter((item) => item.shifts.length === 0),
				"HOURS COMPLETED": () => {
					const workersWithShifts = allData.filter(
						(item) => item.shifts.length > 0
					);
					return workersWithShifts.map((item) => ({
						...item,
						shifts: item.shifts.filter(
							(shift) => shift.attendanceStatus.toLowerCase() !== "a"
						),
					}));
				},
				"NO CALL NO SHOWS": () => {
					const workersWithShifts = allData.filter(
						(item) =>
							item.shifts.length > 0 &&
							item.shifts.some(
								(shift) => shift.attendanceStatus.toLowerCase() === "a"
							)
					);
					return workersWithShifts.map((item) => ({
						...item,
						shifts: item.shifts.filter(
							(shift) => shift.attendanceStatus.toLowerCase() === "a"
						),
					}));
				},
			};

			// Apply the filter based on the selected KPI
			state.dailyActivityReport.kpisData =
				kpiFilters[action.payload]?.() || allData;
			state.selectedDayKPI = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getMonthlyActivityReport.pending, (state, action) => {
			state.monthlyActivityReport.status = "pending";
		});
		builder.addCase(getMonthlyActivityReport.fulfilled, (state, action) => {
			state.monthlyActivityReport.status = "idle";
			const workers = action.payload;

			const metrics = workers.reduce(
				(acc: Metrics, worker: Worker) => {
					const hasShifts = worker.shifts.length > 0;

					if (hasShifts) {
						acc.workersWithShifts++;

						const shiftMetrics = worker.shifts.reduce(
							(shiftAcc, shift: Shift) => {
								if (shift.attendanceStatus.toLowerCase() === "a") {
									shiftAcc.noShows++;
								} else {
									shiftAcc.hours += Math.round(
										Math.abs(
											differenceInMinutes(
												new Date(shift.shiftEndDateTime),
												new Date(shift.shiftStartDateTime)
											)
										) / 60
									);
								}
								return shiftAcc;
							},
							{ hours: 0, noShows: 0 }
						);

						acc.hoursCompleted += shiftMetrics.hours;
						acc.noCallNoShows += shiftMetrics.noShows;
					}

					return acc;
				},
				{
					workersWithShifts: 0,
					hoursCompleted: 0,
					noCallNoShows: 0,
					total: workers.length,
				}
			);
			state.monthlyKPISummaries = {
				"WORKERS WITH AT LEAST 1 SHIFT": metrics.workersWithShifts,
				"WORKERS WITH NO SHIFTS": metrics.total - metrics.workersWithShifts,
				"HOURS COMPLETED": metrics.hoursCompleted,
				"NO CALL NO SHOWS": metrics.noCallNoShows,
				ALL: metrics.total,
			};

			state.monthlyActivityReport.allData = workers;
			state.monthlyActivityReport.kpisData = workers;
		});
		builder.addCase(getMonthlyActivityReport.rejected, (state, action:any) => {
			if(action.payload?.status === 429) {
				state.monthlyActivityReport.status = "idle";
			} else {
			state.monthlyActivityReport.status = "idle";
			state.monthlyActivityReport.errorMessage = action.payload as string;
			}
		});
		builder.addCase(getDailyActivityReport.pending, (state, action) => {
			state.dailyActivityReport.status = "pending";
		});
		builder.addCase(getDailyActivityReport.fulfilled, (state, action) => {
			state.dailyActivityReport.status = "idle";
			const workers = action.payload;

			const metrics = workers.reduce(
				(acc: Metrics, worker: Worker) => {
					const hasShifts = worker.shifts.length > 0;

					if (hasShifts) {
						acc.workersWithShifts++;

						const shiftMetrics = worker.shifts.reduce(
							(shiftAcc, shift: Shift) => {
								if (shift.attendanceStatus.toLowerCase() === "a") {
									shiftAcc.noShows++;
								} else {
									shiftAcc.hours += Math.round(
										Math.abs(
											differenceInMinutes(
												new Date(shift.shiftEndDateTime),
												new Date(shift.shiftStartDateTime)
											)
										) / 60
									);
								}
								return shiftAcc;
							},
							{ hours: 0, noShows: 0 }
						);

						acc.hoursCompleted += shiftMetrics.hours;
						acc.noCallNoShows += shiftMetrics.noShows;
					}

					return acc;
				},
				{
					workersWithShifts: 0,
					hoursCompleted: 0,
					noCallNoShows: 0,
					total: workers.length,
				}
			);

			state.dayKPISummaries = {
				"WORKERS WITH AT LEAST 1 SHIFT": metrics.workersWithShifts,
				"WORKERS WITH NO SHIFTS": metrics.total - metrics.workersWithShifts,
				"HOURS COMPLETED": metrics.hoursCompleted,
				"NO CALL NO SHOWS": metrics.noCallNoShows,
				ALL: metrics.total,
			};

			state.dailyActivityReport.allData = workers;
			state.dailyActivityReport.kpisData = workers;
		});
		builder.addCase(getDailyActivityReport.rejected, (state, action:any) => {
			if(action.payload?.status === 429) {
				state.dailyActivityReport.status = "idle";
			} else{
				state.dailyActivityReport.status = "idle";
				state.dailyActivityReport.errorMessage = action.payload as string;
			}
		});
	},
});

export const { setSelectedKPI, setSelectedDayKPI } =
	WorkerActivitySlice.actions;
export default WorkerActivitySlice.reducer;
