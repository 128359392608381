import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";

import { generateUsername } from "../../../../js/utils/helpers";
import useUserData from "./use-user-data";

const UserDetails: React.FC = () => {
  const { company, email, firstName, lastName } = useUserData();

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <div
        style={{
          height: "32px",
          width: "32px ",
          borderRadius: "50%",
          background: "#00000099",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px",
          marginLeft:"22px",
          flexShrink: 0,
          cursor: "pointer",
        }}
      >
        <div>{generateUsername(firstName, lastName)}</div>
      </div>
      <Box style={{ marginTop: "12px",marginBottom: "12px",color: "#00000099" }}>
        <Tooltip title={email}>
          <Typography
            sx={{
              fontSize: "12px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "180px",
              fontWeight: "400",
              alignSelf:"center",
              lineHeight:"20px",
              justifyContent:"center",

            }}
          >
            {email}
          </Typography>
        </Tooltip>
        <Tooltip title={company}>
          <Typography
            sx={{
              fontSize: "12px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "180px",
              fontWeight: "400",
              alignSelf:"flex-end",
              lineHeight:"20px",
              justifyContent:"flex-end",
            }}
          >
            {company}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default UserDetails;
