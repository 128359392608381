/* eslint-disable simple-import-sort/imports */
import { Highlight } from "../../../../../../export/helpers";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import useTableHeight from "../../../../../utils/use-table-height";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { WorkerInsightModel } from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface WorkerBackup {
	id: string | undefined;
	name: string;
	primaryJobName: string | undefined;
	locationWorkcenter: string;
}

function WorkerInsightDetailsWorkerBackupTab({
	workersReportData,
	workerId,
	allLocations,
}: {
	workersReportData: WorkerInsightModel[];
	workerId: string;
	allLocations: string[];
}) {
	const { height, boxRef } = useTableHeight();
	const [searchTerm, setSearchTerm] = useState("");
	const [rows, setRows] = useState<WorkerBackup[]>([]);

	useEffect(() => {
		try {
			const newRows: WorkerBackup[] = [];
			workersReportData
				.find((worker) => worker.workerId === workerId)
				?.workerBackupList?.map((workerBackUp) => {
					if (allLocations.includes(workerBackUp.location)) {
						const workerBackUpDetails = workersReportData.find(
							(worker) => worker.workerId === workerBackUp.workerId
						);
						newRows.push({
							id: workerBackUpDetails?.workerId,
							name:
								workerBackUpDetails?.firstName +
								" " +
								workerBackUpDetails?.lastName,
							primaryJobName:
								workerBackUpDetails?.primaryJobName === ""
									? "-"
									: workerBackUpDetails?.primaryJobName,
							locationWorkcenter: `${workerBackUpDetails?.locationName}${
								workerBackUpDetails?.workcenterName === ""
									? ""
									: " - " + workerBackUpDetails?.workcenterName
							}`,
						});
					}
				});
			setRows(newRows);
		} catch (e) {
			// Do nothing
		}
	}, [allLocations, workerId, workersReportData]);

	const filteredData = useMemo(() => {
		return rows
			.sort((a, b) => a.name.localeCompare(b.name))
			.filter(
				(item: {
					id: string | undefined;
					name: string;
					primaryJobName: string | undefined;
					locationWorkcenter: string;
				}) =>
					item.name.toLowerCase().includes(searchTerm) ||
					(item.primaryJobName
						? item.primaryJobName.toLowerCase().includes(searchTerm)
						: "") ||
					item.locationWorkcenter.toLowerCase().includes(searchTerm)
			);
	}, [rows, searchTerm]);

	const ListItems: React.FC<{
		item: WorkerBackup;
	}> = ({ item }) => {
		return (
			<Box
				key={`worker-backup-${item.id}`}
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "center",
					flexDirection: "column",
					width: "100%",
					border: 1,
					borderLeftWidth: 0,
					borderRightWidth: 0,
					borderTopWidth: 0,
					borderBottomWidth: "1px",
					borderBottomColor: "#F0F0F3",
					height: "87px",
					paddingLeft: "16px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						lineHeight: "24px",
						color: "rgba(0, 0, 0, 0.87)",
						fontWeight: 700,
					}}
				>
					<Highlight value={item.name} searchText={searchTerm} />
				</Typography>
				{item.primaryJobName && (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							lineHeight: "20px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						<Highlight value={item.primaryJobName} searchText={searchTerm} />
					</Typography>
				)}
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "14px",
						lineHeight: "20px",
						color: "rgba(0, 0, 0, 0.60)",
					}}
				>
					<Highlight value={item.locationWorkcenter} searchText={searchTerm} />
				</Typography>
			</Box>
		);
	};

	return (
		<Box
			ref={boxRef}
			style={{
				width: "100%",
				paddingTop: 10,
			}}
		>
			{rows.length > 0 ? (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-start",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						padding: "8px",
					}}
				>
					<TextField
						label='Search'
						fullWidth
						placeholder='Worker Name, Primary Job, Location, Workcenter'
						variant='filled'
						onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
						size='small'
						value={searchTerm}
						InputProps={{
							endAdornment: searchTerm !== undefined &&
								searchTerm.length > 0 && (
									<CancelOutlinedIcon
										sx={{ cursor: "pointer", color: "#92918f" }}
										onClick={() => setSearchTerm("")}
									/>
								),
						}}
						sx={{
							"& .MuiFilledInput-underline:before": {
								borderBottom: "none",
							},
							"& .MuiFilledInput-underline:after": {
								borderBottom: "none",
							},
							//paddingTop: "4px",
							marginBottom: "16px",
							// marginLeft: "24px",
						}}
					/>
					<FixedSizeList
						width='100%'
						height={height - 48}
						itemCount={filteredData.length}
						itemSize={88}
					>
						{({ index, style }) => (
							<Box style={style}>
								<ListItems item={filteredData[index]} />
							</Box>
						)}
					</FixedSizeList>
				</Box>
			) : (
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						mt: 10,
					}}
				>
					<PersonOffIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
					No Backup available
				</Box>
			)}
		</Box>
	);
}

export default WorkerInsightDetailsWorkerBackupTab;
