import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DUMMY_PRIORITIES_ARRAY } from "../../../assets/constants";
import axios from "../../../utils/api-client";
import { locationOfWorkcenter } from "../../../utils/helpers";
import { ShiftPatternAPIProps, SkillLevel } from "../../workers/types";
import { setSupervisorRateLimitError } from "./../../../features/user/user-slice";
import {
  CompanyLocation,
  CompanyUser,
  CompanyWorkCenter,
  CreateLocationPoolPayload,
  CreateNewCompanyPayloadType,
  CreateNewLocationPayload,
  CreateNewSchedulePayloadType,
  CreateUserPayload,
  DemoCompanyType,
  EditLocationClusterPayload,
  EditNewSchedulePayloadType,
  GATAdminState,
  LeaveType,
  OneCompany,
  SaveSkillPrioritiesPayload,
  Setting,
  ShiftAllocationRulesAllocationType,
  ShiftType,
  Team,
  TimeOffTypePayload,
  UpdateCompanyUserPayload,
  UpdateLocationDataPayload,
  UpdateWorkCenterDataPayload,
  UserWorkCenter,
  WorkerNotifyCats,
} from "./gat-admin-slice-types";

const initialState: GATAdminState = {
  openLocationDialog: false,
  openLocationClusterDialog: false,
  openWorkCenterDialog: false,
  openCompanyUserDialog: false,
  openSetUpDemoOwnerDialog: false,
  openCrossSkillingLevelsDialog: false,
  openCrossSkillingPrioritiesDialog: false,
  creatingNewCompany: false,
  creatingNewLocation: false,
  skillPriorities: [...DUMMY_PRIORITIES_ARRAY],
  allCompanies: [],
  locations: [],
  companySettings: {},
  locationSettings: {},
  companyData: {},
  companyLocationClusters: [],
  currentLocationData: {},
  currentCompanyData: {},
  companyUsers: [],
  companyNotAssignedSkillLevel: null,
  allSettingTypes: [],
  teams: [],
  createLocationPool: {
    status: "idle",
    errorMessage: "",
  },
  editLocationPool: {
    status: "idle",
    errorMessage: "",
  },
  deleteLocationPool: {
    status: "idle",
    errorMessage: "",
  },
  getAllCompanies: {
    status: "idle",
    errorMessage: "",
  },
  getAllSettingsTypes: {
    status: "idle",
    errorMessage: "",
  },
  getAllShiftAllocationRules: {
    status: "idle",
    errorMessage: "",
    allocationTypes: [],
  },
  getAllJobsByLocation: {
    status: "idle",
    errorMessage: "",
    jobs: [],
    jobsObject: {},
  },
  getCompanySettings: {
    status: "idle",
    errorMessage: "",
  },
  getLocationSettings: {
    status: "idle",
    errorMessage: "",
  },
  getCompanyData: {
    status: "idle",
    errorMessage: "",
  },
  getCompanyLocationClusters: {
    status: "idle",
    errorMessage: "",
  },
  createNewCompany: {
    status: "idle",
    errorMessage: "",
    newCompanyId: null,
  },
  getCompanyUsers: {
    status: "idle",
    errorMessage: "",
  },
  createCompanyUser: {
    status: "idle",
    errorMessage: "",
  },
  updateCompanyUser: {
    status: "idle",
    errorMessage: "",
  },
  updateCompanyData: {
    status: "idle",
    errorMessage: "",
  },
  updateCompanySettings: {
    status: "idle",
    errorMessage: "",
  },
  updateCompanySettingsLevel: {
    status: "idle",
    errorMessage: "",
  },
  getCompanyNotAssignedSkillLevel: {
    status: "idle",
    errorMessage: "",
  },
  updateCompanyNotAssignedSkillLevel: {
    status: "idle",
    errorMessage: "",
  },
  getLocations: {
    status: "idle",
    errorMessage: "",
  },
  getAllTimeZones: {
    status: "idle",
    errorMessage: "",
  },
  createNewLocation: {
    status: "idle",
    errorMessage: "",
    locationId: null,
    newLocationId: null,
  },
  updateLocationData: {
    status: "idle",
    errorMessage: "",
  },
  getRolesForAdmin: {
    status: "idle",
    errorMessage: "",
  },
  allCompanyUserRoles: [],
  timeZones: [],
  allCompanyWorkCenters: [],
  updateWorkCenterData: {
    status: "idle",
    errorMessage: "",
  },
  createNewWorkCenter: {
    status: "idle",
    errorMessage: "",
  },
  getWorkerTypes: {
    status: "idle",
    errorMessage: "",
  },
  workerTypes: [],
  getAllShiftTypes: {
    status: "idle",
    errorMessage: "",
    data: [],
  },
  getWorkerNotificationTypes: {
    status: "idle",
    errorMessage: "",
    data: [],
  },
  createNewSchedule: {
    status: "idle",
    errorMessage: "",
  },
  editShiftSchedule: {
    status: "idle",
    errorMessage: "",
    data: null,
    isDialogOpen: false,
  },
  deleteShiftSchedule: {
    status: "idle",
    errorMessage: "",
  },
  getAllTeamsByLocation: {
    status: "idle",
    errorMessage: "",
  },
  getAllManagers: {
    status: "idle",
    errorMessage: "",
    data: [],
  },
  createNewTeam: {
    status: "idle",
    errorMessage: "",
  },
  editTeam: {
    status: "idle",
    errorMessage: "",
    data: null,
    isDialogOpen: false,
  },
  getCompanyManagers: {
    status: "idle",
    errorMessage: "",
    data: [],
  },
  removeShiftPattern: {
    status: "idle",
    errorMessage: "",
  },

  updateWorkerNotificationTypes: {
    status: "idle",
    errorMessage: "",
  },
  getSkillPriorities: {
    status: "idle",
    errorMessage: "",
  },
  isDeactivateDialog: {
    open: false,
    data: null,
  },
  changeUserStatus: {
    status: "idle",
    errorMessage: "",
  },
  getLeaveTypes: {
    status: "idle",
    errorMessage: "",
    data: [],
  },
  createTimeOffType: {
    status: "idle",
    errorMessage: "",
  },
  editTimeOffType: {
    status: "idle",
    errorMessage: "",
  },
  deleteTimeOffType: {
    status: "idle",
    errorMessage: "",
  },
  saveSkillPriorities: {
    status: "idle",
    errorMessage: "",
  },
  updateUserRoleToDemoResetRole: {
    status: "idle",
    errorMessage: "",
  },
};
// API to get company user using company Id
export const getCompanyUsers = createAsyncThunk(
  "gat-admin/get-company-users",
  async (
    companyId: number,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/${companyId}/users`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to fetch skill priority
export const getSkillPriorities = createAsyncThunk(
  "gat-admin/getSkillPriorities",
  async (
    companyId: number,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/Skill/Priorities?companyId=${companyId}`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const saveSkillPriorities = createAsyncThunk(
  "gat-admin/saveSkillPriorities",
  async (
    data: SaveSkillPrioritiesPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      let idsOfSkillPrioritiesToBeDeleted = data.skillPrioritiesToDelete ?? [];
      const deletedSkillPriorities = [];
      let updatedSkillPriorities = false;
      if (data.skillPrioritiesToUpdate.length !== 0) {
        const updateSkillPriority = await axios.put(
          `${process.env.REACT_APP_API_END_POINT}/Skill/UpdateSkillPriority`,
          data.skillPrioritiesToUpdate
        );
        if (updateSkillPriority.status === 200) {
          updatedSkillPriorities = true;
        } else {
          return rejectWithValue("An error occurred");
        }
      } else {
        updatedSkillPriorities = true;
      }
      if (updatedSkillPriorities) {
        // Higher priorities to be deleted first, that is why sorted in descending order
        idsOfSkillPrioritiesToBeDeleted = idsOfSkillPrioritiesToBeDeleted.sort(
          function (a, b) {
            return b - a;
          }
        );

        if (idsOfSkillPrioritiesToBeDeleted.length > 0) {
          for (const idOfSkillPriorityToDelete of idsOfSkillPrioritiesToBeDeleted) {
            const deleteSkillPriorityResponse = await axios.delete(
              `${process.env.REACT_APP_API_END_POINT}/skill/DeletePriority/${idOfSkillPriorityToDelete}`
            );
            if (deleteSkillPriorityResponse.status === 200) {
              deletedSkillPriorities.push(idOfSkillPriorityToDelete);
            }
          }
        }
      }
      if (
        deletedSkillPriorities.length ===
          idsOfSkillPrioritiesToBeDeleted.length &&
        updatedSkillPriorities
      ) {
        if (data.skillPrioritiesToSave.length !== 0) {
          const createNewSkillPriority = await axios.post(
            `${process.env.REACT_APP_API_END_POINT}/Skill/CreateSkillPriority`,
            data.skillPrioritiesToSave
          );
          if (createNewSkillPriority.status === 201) {
            return fulfillWithValue(data);
          } else {
            return rejectWithValue("An error occurred");
          }
        } else {
          return fulfillWithValue(data);
        }
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

// API to create company user
export const createCompanyUser = createAsyncThunk(
  "gat-admin/create-company-user",
  async (
    data: CreateUserPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/users/create`,
        data
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to create company user
export const updateCompanyUser = createAsyncThunk(
  "gat-admin/update-company-user",
  async (
    data: UpdateCompanyUserPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/users/update`,
        data
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all companies
export const getAllCompanies = createAsyncThunk(
  "gat-admin/get-all-companies",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      //to bring demo owner field and list of users available for the each company
      if (
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "local" ||
        process.env.REACT_APP_ENV === "demo"
      ) {
        const [companiesRes, demoCompaniesRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_END_POINT}/companies/getallcompanies`
          ),
          axios.get(
            `${process.env.REACT_APP_API_END_POINT}/demoreset/getAllCompanyDemoUsers`
          ),
        ]);

        const companies = companiesRes.data;
        const demoCompanies = demoCompaniesRes.data;

        const mergedData = companies.map((company: OneCompany) => {
          const demoData = demoCompanies.find(
            (demo: DemoCompanyType) => demo.id === company.id
          );
          return { ...company, ...demoData };
        });

        return fulfillWithValue(mergedData);
      } else {
        const res = await axios.get(
          `${process.env.REACT_APP_API_END_POINT}/companies/getallcompanies`
        );
        return fulfillWithValue(res.data);
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      const errorMessage = error.response?.data?.message || "An error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateUserRoleToDemoResetRole = createAsyncThunk(
  "gat-admin/change-demo-owner",
  async (
    payload: { companyId: number; userId: number },
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const { companyId, userId } = payload;
    const state = getState() as any;
    const createdBy = Number.parseInt(state.user.userData.id);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/demoreset/UpdateDemoUserRole`,
        {
          companyId,
          userId,
          createdBy,
        }
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCompanySettings = createAsyncThunk(
  "gat-admin/get-company-settings",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/${payload}/settings`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getLocationSettings = createAsyncThunk(
  "gat-admin/get-location-settings",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/locations/${payload}/settings`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getCompanyData = createAsyncThunk(
  "gat-admin/get-company-data",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/getcompany/${payload}`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getCompanyLocationClusters = createAsyncThunk(
  "gat-admin/get-company-location-clusters",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/${payload}/locationpools/`
      );
      if (res.status === 200) {
        return fulfillWithValue(res.data);
      } else if (res.status === 204) {
        return fulfillWithValue([]);
      }
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
export const updateCompanyData = createAsyncThunk(
  "gat-admin/update-company-data",
  async (
    payload: {
      id: number;
      name: string;
      hqFullAddress: string;
    },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/v2/company`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// update company settings

export const updateCompanySettings = createAsyncThunk(
  "gat-admin/update-company-settings",
  async (
    payload: Setting[],
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/settings/setting`,
        {
          Settings: payload,
        }
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);
// update company settings skill level
export const updateCompanySettingsLevel = createAsyncThunk(
  "gat-admin/update-company-settings-level",
  async (
    payload: { companyId: string; skillLevels: SkillLevel[] },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      // Delete skill levels first higher to lower levels
      let idsOfSkillLevelsToBeDeleted: number[] = [];
      const deletedSkillLevels = [];
      const skillLevels = Object.prototype.hasOwnProperty.call(
        payload,
        "skillLevels"
      )
        ? payload.skillLevels
        : [];
      skillLevels.forEach((level) => {
        if (level.toBeDeleted) {
          idsOfSkillLevelsToBeDeleted.push(level.id);
        }
      });

      // Higher level to be deleted first, that is why sorted in descending order
      idsOfSkillLevelsToBeDeleted = idsOfSkillLevelsToBeDeleted.sort(function (
        a,
        b
      ) {
        return b - a;
      });

      if (idsOfSkillLevelsToBeDeleted.length > 0) {
        for (const idOfSkillLevelToDelete of idsOfSkillLevelsToBeDeleted) {
          const deleteSkillLevelResponse = await axios.delete(
            `${process.env.REACT_APP_API_END_POINT}/skill/deletelevel/${idOfSkillLevelToDelete}`
          );
          if (deleteSkillLevelResponse.status === 200) {
            deletedSkillLevels.push(idOfSkillLevelToDelete);
          }
        }
      }

      if (deletedSkillLevels.length === idsOfSkillLevelsToBeDeleted.length) {
        const res = await axios.put(
          `${process.env.REACT_APP_API_END_POINT}/Companies/skilllevels`,
          payload
        );
        if (res.status === 200) {
          return fulfillWithValue(payload);
        }
        return rejectWithValue("An error occurred");
      }
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getCompanyNotAssignedSkillLevel = createAsyncThunk(
  "gat-admin/get-company-not-assigned-skill-level",
  async (payload: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/SkillRepresentation/GetNoWorkerSkillRep?companyId=${payload}`
      );
      if (res.status === 200) {
        return fulfillWithValue(res.data);
      } else if (res.status === 204) {
        return fulfillWithValue({
          name: "",
          repCode: null,
          value: null,
        });
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const updateCompanyNotAssignedSkillLevel = createAsyncThunk(
  "gat-admin/update-company-not-assigned-skill-level",
  async (
    payload: { companyId: string; notAssignedSkillLevel: any },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const toDelete =
        payload.notAssignedSkillLevel.value === "--" ||
        payload.notAssignedSkillLevel.repCode === "--";
      if (toDelete) {
        console.log("Deleting");
        const deleteRes = await axios.delete(
          `${process.env.REACT_APP_API_END_POINT}/SkillRepresentation/DeleteNoWorkerSkillRep?companyId=${payload.companyId}`
        );
        if (deleteRes.status === 200) {
          return fulfillWithValue(payload);
        }
      } else {
        console.log("Adding");
        const res = await axios.put(
          `${process.env.REACT_APP_API_END_POINT}/SkillRepresentation/UpdateNoWorkerSkillRep?companyId=${payload.companyId}`,
          payload.notAssignedSkillLevel
        );
        if (res.status === 200) {
          return fulfillWithValue(payload);
        }
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// Not in use
export const deleteCompanyNotAssignedSkillLevel = createAsyncThunk(
  "gat-admin/delete-company-not-assigned-skill-level",
  async (
    payload: { companyId: string },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/SkillRepresentation/DeleteNoWorkerSkillRep?companyId=${payload.companyId}`
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getWorkerTypes = createAsyncThunk(
  "gat-admin/get-worker-type",
  async (_payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Types`
      );
      if (res.status === 200) {
        return fulfillWithValue(res.data);
      } else if (res.status === 204) {
        return fulfillWithValue([]);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getLocations = createAsyncThunk(
  "gat-admin/get-locations",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/${payload}/locations/`
      );
      if (res.status === 200) {
        return fulfillWithValue(res.data);
      } else if (res.status === 204) {
        return fulfillWithValue([]);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const createNewCompany = createAsyncThunk(
  "gat-admin/create-new-company",
  async (
    payload: CreateNewCompanyPayloadType,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    const { name, hqFullAddress } = payload;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/v2/company`,
        {
          name: name,
          hqFullAddress: hqFullAddress,
        }
      );
      if (res.status === 201) {
        return fulfillWithValue({ ...payload, ...res.data });
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getAllTimeZones = createAsyncThunk(
  "gat-admin/get-all-timezones",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/locations/timezones`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const createNewLocation = createAsyncThunk(
  "gat-admin/create-new-location",
  async (
    payload: CreateNewLocationPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/locations/create`,
        payload
      );
      if (res.status === 200 || res.status === 201) {
        return fulfillWithValue({
          ...payload,
          locationId: res.data,
          id: res.data,
        });
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const updateLocationData = createAsyncThunk(
  "gat-admin/update-location-data",
  async (
    payload: UpdateLocationDataPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/locations/edit`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getRolesForAdmin = createAsyncThunk(
  "gat-admin/get-roles-for-admin",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/Users/RolesForAdmin`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const createNewWorkCenter = createAsyncThunk(
  "gat-admin/create-new-work-center",
  async (
    payload: {
      id: number;
      name: string;
      costCenter: string;
      locationId: string;
    },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/WorkCenters/CreateWorkcenter`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const updateWorkCenterData = createAsyncThunk(
  "gat-admin/update-work-center-data",
  async (
    payload: UpdateWorkCenterDataPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/WorkCenters/EditWorkcenter`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all companies
export const getAllShiftAllocationRules = createAsyncThunk(
  "gat-admin/get-all-shift-allocation-rules",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/locations/GetAllAllocationTypes`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all companies
export const getAllJobsByLocation = createAsyncThunk(
  "gat-admin/get-all-jobs-by-location",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/locations/GetJobsAtLocation?locationId=${payload}`
      );
      if (res.status === 200) return fulfillWithValue(res.data);
      else if (res.status === 204) return fulfillWithValue([]);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all shift types
export const getAllShiftTypes = createAsyncThunk(
  "gat-admin/get-all-shift-types",
  async (
    payload: number | string,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/types/${payload}`
      );
      if (res.status === 200) return fulfillWithValue(res.data);
      else if (res.status === 204) return fulfillWithValue([]);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

//create new schedule
export const createNewSchedule = createAsyncThunk(
  "gat-admin/create-new-schedule",
  async (
    payload: CreateNewSchedulePayloadType,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    const {
      scheduleName,
      companyId,
      startTime,
      endTime,
      breakDuration,
      locationId,
    } = payload;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/type`,
        {
          name: scheduleName,
          companyId: companyId,
          startTime: startTime + ":00",
          endTime: endTime + ":00",
          unpaidBreakMins: breakDuration,
          locationId: locationId,
        }
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// edit shift schedule

export const editShiftSchedule = createAsyncThunk(
  "gat-admin/edit-shift-schedule",
  async (
    payload: EditNewSchedulePayloadType,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    const {
      scheduleName,
      companyId,
      startTime,
      endTime,
      id,
      locationId,
      breakDuration,
    } = payload;
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/type`,
        {
          name: scheduleName,
          companyId: companyId,
          startTime: startTime + ":00",
          endTime: endTime + ":00",
          locationId: locationId,
          unpaidBreakMins: breakDuration,
          id: id,
        }
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// edit shift schedule

export const deleteShiftSchedule = createAsyncThunk(
  "gat-admin/delete-shift-schedule",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/type/${payload}`
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all settings types
export const getAllSettingsTypes = createAsyncThunk(
  "gat-admin/get-all-settings-types",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/settings/types`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const deleteLocationPool = createAsyncThunk(
  "gat-admin/delete-location-pool",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/locations/pool/${payload}`
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const editLocationPool = createAsyncThunk(
  "gat-admin/edit-location-pool",
  async (
    payload: EditLocationClusterPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/locations/pool`,
        payload
      );
      if (payload.statusChanged) {
        const switchLocationPoolStatus = await axios.post(
          `${process.env.REACT_APP_API_END_POINT}/locations/pool/toggle/${payload.id}`
        );
        if (res.status === 200 && switchLocationPoolStatus.status === 200) {
          return fulfillWithValue(payload);
        }
      } else {
        if (res.status === 200) {
          return fulfillWithValue(payload);
        }
      }

      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const createLocationPool = createAsyncThunk(
  "gat-admin/create-location-pool",
  async (
    payload: CreateLocationPoolPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/locations/pool`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all Teams by location
export const getAllTeamsByLocation = createAsyncThunk(
  "gat-admin/get-all-teams-by-location",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/patterns/${payload}`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get all Managers
export const getAllManagers = createAsyncThunk(
  "manageWorkers/getAllManagers",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const organizations = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/managers`
      );
      return fulfillWithValue(organizations.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.message);
    }
  }
);

// API to get all Managers
export const getCompanyManagers = createAsyncThunk(
  "manageWorkers/getCompanyManagers",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const organizations = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/companies/${payload}/users`
      );
      return fulfillWithValue(organizations.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.message);
    }
  }
);

// API to create new team

export const createNewTeam = createAsyncThunk(
  "gat-admin/create-new-team",
  async (
    payload: ShiftPatternAPIProps,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const allTeams = (getState() as any).gatAdminConfiguration.teams;
    const isDuplicate = allTeams.some(
      (team: { name: string }) =>
        team.name.toLowerCase() === payload.name.toLowerCase()
    );
    if (isDuplicate) {
      return rejectWithValue("Shift Pattern name already exists");
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/pattern`,
        payload
      );
      if (res.status === 200 || res.status === 201) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// Api to edit team
export const editTeam = createAsyncThunk(
  "gat-admin/edit-team",
  async (
    payload: ShiftPatternAPIProps,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const allTeams = (getState() as any).gatAdminConfiguration.teams;
    const isDuplicate = allTeams.some(
      (team: { name: string; id: number }) =>
        team.name.toLowerCase() === payload.name.toLowerCase() &&
        team.id !== payload.id
    );
    if (isDuplicate) {
      return rejectWithValue("Shift Pattern name already exists");
    }

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/pattern`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error.response.data);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const removeShiftPattern = createAsyncThunk(
  "gat-admin/remove-shift-pattern",
  async (
    payload: any,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/ftshifts/pattern/${payload}`
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        if (error.response.data) {
          if (error.response.data.message) {
            return rejectWithValue(error.response.data.message);
          } else {
            return rejectWithValue(error.response.data);
          }
        } else {
          return rejectWithValue("An error occurred");
        }
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  "gat-admin/change-user-status",
  async (
    payload: {
      userId: number;
      isActive: boolean;
      alternateSupervisorId: number | null;
    },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const { userId, isActive, alternateSupervisorId } = payload;
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/Users/${userId}/UpdateStatus`,
        { isActive, alternateSupervisorId }
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      try {
        const errorResponse = error?.response?.data;
        errorResponse.status = error?.response?.status;
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        if (error.response.data) {
          if (error.response.data.message) {
            return rejectWithValue(error.response.data.message);
          } else {
            return rejectWithValue(error.response.data);
          }
        } else {
          return rejectWithValue("An error occurred");
        }
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const getWorkerNotificationTypes = createAsyncThunk(
  "gat-admin/get-worker-notification-types",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/locations/${payload}/workerNotifyCats`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateWorkerNotificationTypes = createAsyncThunk(
  "gat-admin/update-worker-notification-types",
  async (
    payload: WorkerNotifyCats[],
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/settings/workerNotifyCats`,
        payload
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getLeaveTypes = createAsyncThunk(
  "gat-admin/get-leave-types",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/company/${payload}/leaveTypes`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createTimeOffType = createAsyncThunk(
  "gat-admin/create-time-off-type",
  async (
    payload: any,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as any;
      const gatState = state.gatAdminConfiguration as GATAdminState;

      const leaveTypes = gatState.getLeaveTypes.data;

      const isDuplicate = leaveTypes.some(
        (leaveType: LeaveType) =>
          leaveType.name.toLowerCase() === payload.name.toLowerCase()
      );

      if (isDuplicate) {
        return rejectWithValue(
          "Another Time Off Type exists with the given Name"
        );
      }

      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/company/leave/type`,
        payload
      );
      return fulfillWithValue({ id: res.data, ...payload });
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editTimeOffType = createAsyncThunk(
  "gat-admin/edit-time-off-type",
  async (
    payload: TimeOffTypePayload,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as any;
      const gatState = state.gatAdminConfiguration as GATAdminState;

      const leaveTypes = gatState.getLeaveTypes.data;
      const isDuplicate = leaveTypes.some(
        (leaveType: LeaveType) =>
          leaveType.name.toLowerCase() === payload.name.toLowerCase() &&
          leaveType.id !== payload.id
      );

      if (isDuplicate) {
        return rejectWithValue(
          "Another Time Off Type exists with the given Name"
        );
      }
      const res = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/company/leave/type`,
        payload
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return fulfillWithValue(payload);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteTimeOffType = createAsyncThunk(
  "gat-admin/delete-time-off-type",
  async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/company/leave/type/${payload}`
      );
      if (res.status === 200) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue("An error occurred");
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      errorResponse.status = error?.response?.status;
      if (errorResponse.status === 429) {
        dispatch(setSupervisorRateLimitError());
        return rejectWithValue(errorResponse);
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const GATAdminConfigurationSlice = createSlice({
  name: "gatAdminConfiguration",
  initialState,
  reducers: {
    resetCreateNewCompany: (state) => {
      state.createNewCompany.status = "idle";
      state.createNewCompany.errorMessage = "";
      state.createNewCompany.newCompanyId = null;
    },
    resetCreateNewLocation: (state) => {
      state.createNewLocation.status = "idle";
      state.createNewLocation.errorMessage = "";
    },
    resetUpdateCompanySettings: (state) => {
      state.updateCompanySettings.status = "idle";
      state.updateCompanySettings.errorMessage = "";
    },
    resetUpdateCompanySettingsLevel: (state) => {
      state.updateCompanySettingsLevel.status = "idle";
      state.updateCompanySettingsLevel.errorMessage = "";
    },
    resetUpdateCompanyData: (state) => {
      state.updateCompanyData.status = "idle";
      state.updateCompanyData.errorMessage = "";
    },
    resetCompanySettings: (state) => {
      state.getCompanySettings.status = "idle";
      state.getCompanySettings.errorMessage = "";
      state.companySettings = {};
    },
    resetSaveSkillPriorities: (state) => {
      state.saveSkillPriorities.status = "idle";
      state.saveSkillPriorities.errorMessage = "";
      state.skillPriorities = [];
    },
    resetCompanyData: (state) => {
      state.getCompanyData.status = "idle";
      state.getCompanyData.errorMessage = "";
      state.companyData = {};
    },
    resetCompanyLocationClusters: (state) => {
      state.getCompanyLocationClusters.status = "idle";
      state.getCompanyLocationClusters.errorMessage = "";
      state.companyLocationClusters = [];
    },
    resetUpdateLocationData: (state) => {
      state.updateLocationData.status = "idle";
      state.updateLocationData.errorMessage = "";
    },
    resetLocations: (state) => {
      state.getLocations.status = "idle";
      state.getLocations.errorMessage = "";
      state.locations = [];
    },
    resetCompanyUsers: (state) => {
      state.getCompanyUsers.status = "idle";
      state.getCompanyUsers.errorMessage = "";
      state.companyUsers = [];
    },
    resetCreateCompanyUser: (state) => {
      state.createCompanyUser.status = "idle";
      state.createCompanyUser.errorMessage = "";
    },
    resetUpdateCompanyUser: (state) => {
      state.updateCompanyUser.status = "idle";
      state.updateCompanyUser.errorMessage = "";
    },
    resetCreateNewWorkCenter: (state) => {
      state.createNewWorkCenter.status = "idle";
      state.createNewWorkCenter.errorMessage = "";
    },
    resetUpdateWorkCenterData: (state) => {
      state.updateWorkCenterData.status = "idle";
      state.updateWorkCenterData.errorMessage = "";
    },
    setLocationDialog: (state, action) => {
      state.openLocationDialog = action.payload;
      state.openWorkCenterDialog = false;
      state.openCompanyUserDialog = false;
      state.openLocationClusterDialog = false;
    },
    setLocationClusterDialog: (state, action) => {
      state.openLocationClusterDialog = action.payload;
      state.openWorkCenterDialog = false;
      state.openCompanyUserDialog = false;
      state.openLocationDialog = false;
    },
    setWorkCenterDialog: (state, action) => {
      state.openWorkCenterDialog = action.payload;
      state.openCompanyUserDialog = false;
      state.openLocationDialog = false;
      state.openLocationClusterDialog = false;
    },
    setCompanyUserDialog: (state, action) => {
      state.openCompanyUserDialog = action.payload;
      state.openLocationDialog = false;
      state.openWorkCenterDialog = false;
      state.openLocationClusterDialog = false;
    },
    setDemoSetupOwnerDialog: (state, action) => {
      state.openSetUpDemoOwnerDialog = action.payload;
      state.updateUserRoleToDemoResetRole.status = "idle";
    },
    setCrossSkillingLevelsDialog: (state, action) => {
      state.openCrossSkillingLevelsDialog = action.payload;
    },
    setCrossSkillingPrioritiesDialog: (state, action) => {
      state.openCrossSkillingPrioritiesDialog = action.payload;
    },
    resetWorkerTypes: (state) => {
      state.getWorkerTypes.status = "idle";
      state.getWorkerTypes.errorMessage = "";
      state.workerTypes = [];
    },
    setDefaultShiftAllocationRules: (state: GATAdminState, action) => {
      //not using anywhere
      state.getAllShiftAllocationRules.allocationTypes = [
        ...state.getAllShiftAllocationRules.allocationTypes.map(
          (allocationSetting: any) => {
            if (allocationSetting.id === action.payload) {
              return {
                ...allocationSetting,
                isDefault: true,
              };
            } else {
              return {
                ...allocationSetting,
                isDefault: false,
              };
            }
          }
        ),
      ];
    },
    resetCreateNewSchedule: (state) => {
      state.createNewSchedule.status = "idle";
      state.createNewSchedule.errorMessage = "";
    },
    openEditShiftScheduleDialog: (state, action) => {
      state.editShiftSchedule.isDialogOpen = true;
      state.editShiftSchedule.data = action.payload;
    },
    closeEditShiftScheduleDialog: (state) => {
      state.editShiftSchedule.isDialogOpen = false;
      state.editShiftSchedule.data = null;
    },
    resetEditShiftScheduleDialog: (state) => {
      state.editShiftSchedule.status = "idle";
      state.editShiftSchedule.errorMessage = "";
      state.editShiftSchedule.data = null;
      state.editShiftSchedule.isDialogOpen = false;
    },
    resetDeleteShiftSchedule: (state) => {
      state.deleteShiftSchedule.status = "idle";
      state.deleteShiftSchedule.errorMessage = "";
    },
    updateCreateNewCompany: (state, action) => {
      state.creatingNewCompany = action.payload;
    },
    updateCreateNewLocation: (state, action) => {
      state.creatingNewLocation = action.payload;
    },
    setCurrentLocationData: (state, action) => {
      state.currentLocationData = action.payload;
    },
    resetCurrentLocationData: (state) => {
      state.currentLocationData = {};
    },
    setCurrentCompanyData: (state, action) => {
      state.currentCompanyData = action.payload;
    },
    resetCurrentCompanyData: (state) => {
      state.currentCompanyData = {};
    },
    resetCreateLocationPool: (state) => {
      state.createLocationPool.status = "idle";
      state.createLocationPool.errorMessage = "";
    },
    resetEditLocationPool: (state) => {
      state.editLocationPool.status = "idle";
      state.editLocationPool.errorMessage = "";
    },
    resetDeleteLocationPool: (state) => {
      state.deleteLocationPool.status = "idle";
      state.deleteLocationPool.errorMessage = "";
    },
    resetCreateNewTeam: (state) => {
      state.createNewTeam.status = "idle";
      state.createNewTeam.errorMessage = "";
    },
    resetRemoveShiftPattern: (state) => {
      state.removeShiftPattern.status = "idle";
      state.removeShiftPattern.errorMessage = "";
    },
    resetEditTeam: (state) => {
      state.editTeam.status = "idle";
      state.editTeam.errorMessage = "";
      state.editTeam.data = null;
      state.editTeam.isDialogOpen = false;
    },
    openEditTeamDialog: (state, action) => {
      state.editTeam.isDialogOpen = true;
      state.editTeam.data = action.payload;
    },
    closeEditTeamDialog: (state) => {
      state.editTeam.isDialogOpen = false;
      state.editTeam.data = null;
    },
    openUserDeactivateDialog: (state, action) => {
      state.isDeactivateDialog.open = true;
      state.isDeactivateDialog.data = action.payload;
    },
    closeUserDeactivateDialog: (state) => {
      state.isDeactivateDialog.open = false;
      state.isDeactivateDialog.data = null;
    },
    resetUserStatus: (state) => {
      state.changeUserStatus.status = "idle";
      state.changeUserStatus.errorMessage = "";
      state.isDeactivateDialog.open = false;
    },
    resetCreateTimeOffType: (state) => {
      state.createTimeOffType.status = "idle";
      state.createTimeOffType.errorMessage = "";
    },
    resetEditTimeOffType: (state) => {
      state.editTimeOffType.status = "idle";
      state.editTimeOffType.errorMessage = "";
    },
    resetDeleteTimeOffType: (state) => {
      state.deleteTimeOffType.status = "idle";
      state.deleteTimeOffType.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanies.pending, (state: GATAdminState) => {
      state.getAllCompanies.status = "pending";
    });
    builder.addCase(getAllCompanies.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllCompanies.status = "error";
      } else {
        state.getAllCompanies.status = "error";
        state.getAllCompanies.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.allCompanies = action.payload;
      state.getAllCompanies.status = "fulfilled";
    });
    builder.addCase(getAllSettingsTypes.pending, (state) => {
      state.getAllSettingsTypes.status = "pending";
    });
    builder.addCase(getAllSettingsTypes.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllSettingsTypes.status = "error";
      } else {
        state.getAllSettingsTypes.status = "error";
        state.getAllSettingsTypes.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllSettingsTypes.fulfilled, (state, action: any) => {
      state.allSettingTypes = action.payload.reduce(
        (acc: { [key: string]: Setting }, setting: Setting) => {
          acc[setting.code] = setting;
          return acc;
        },
        {}
      );
      state.getAllSettingsTypes.status = "fulfilled";
    });
    builder.addCase(getCompanySettings.pending, (state) => {
      state.getCompanySettings.status = "pending";
      state.getCompanySettings.errorMessage = "";
    });
    builder.addCase(getCompanySettings.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getCompanySettings.status = "error";
      } else {
        state.getCompanySettings.status = "error";
        state.getCompanySettings.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getCompanySettings.fulfilled, (state, action) => {
      state.companySettings = action.payload.reduce(
        (acc: { [key: string]: Setting }, setting: Setting) => {
          acc[setting.code] = setting;
          return acc;
        },
        {}
      );
      state.getCompanySettings.status = "fulfilled";
      state.getCompanySettings.errorMessage = "";
    });
    //Location settings
    builder.addCase(getLocationSettings.pending, (state) => {
      state.getLocationSettings.status = "pending";
      state.getLocationSettings.errorMessage = "";
    });
    builder.addCase(getLocationSettings.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getLocationSettings.status = "error";
      } else {
        state.getLocationSettings.status = "error";
        state.getLocationSettings.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getLocationSettings.fulfilled, (state, action) => {
      state.locationSettings = action.payload.reduce(
        (acc: { [key: string]: Setting }, setting: Setting) => {
          acc[setting.code] = setting;
          return acc;
        },
        {}
      );
      state.getLocationSettings.status = "fulfilled";
      state.getLocationSettings.errorMessage = "";
    });
    builder.addCase(getCompanyData.pending, (state) => {
      state.getCompanyData.status = "pending";
      state.getCompanyData.errorMessage = "";
    });
    builder.addCase(getCompanyData.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getCompanyData.status = "error";
      } else {
        state.getCompanyData.status = "error";
        state.getCompanyData.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getCompanyData.fulfilled, (state, action) => {
      state.getCompanyData.status = "fulfilled";
      state.getCompanyData.errorMessage = "";
      state.companyData = action.payload;
    });
    builder.addCase(getCompanyLocationClusters.pending, (state) => {
      state.getCompanyLocationClusters.status = "pending";
      state.getCompanyLocationClusters.errorMessage = "";
    });
    builder.addCase(
      getCompanyLocationClusters.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.getCompanyLocationClusters.status = "error";
        } else {
          state.getCompanyLocationClusters.status = "error";
          state.getCompanyLocationClusters.errorMessage =
            action.payload ?? "An error occurred";
        }
      }
    );
    builder.addCase(getCompanyLocationClusters.fulfilled, (state, action) => {
      state.getCompanyLocationClusters.status = "fulfilled";
      state.getCompanyLocationClusters.errorMessage = "";
      state.companyLocationClusters = action.payload;
    });
    builder.addCase(updateCompanyData.pending, (state) => {
      state.updateCompanyData.status = "pending";
      state.updateCompanyData.errorMessage = "";
    });
    builder.addCase(updateCompanyData.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.updateCompanyData.status = "error";
      } else {
        state.updateCompanyData.status = "error";
        state.updateCompanyData.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(updateCompanyData.fulfilled, (state, action: any) => {
      state.updateCompanyData.status = "fulfilled";
      state.updateCompanyData.errorMessage = "";
      state.companyData = action.payload;
    });
    builder.addCase(updateCompanySettings.pending, (state) => {
      state.updateCompanySettings.status = "pending";
      state.updateCompanySettings.errorMessage = "";
    });
    builder.addCase(updateCompanySettings.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.updateCompanySettings.status = "error";
      } else {
        state.updateCompanySettings.status = "error";
        state.updateCompanySettings.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(updateCompanySettings.fulfilled, (state) => {
      // Do not use payload into company settings, call api to fetch correct data
      // As we are sending 0 id for server to detect new values and can't use as we are passing

      state.updateCompanySettings.status = "fulfilled";
      state.updateCompanySettings.errorMessage = "";
    });

    builder.addCase(updateCompanySettingsLevel.pending, (state) => {
      state.updateCompanySettingsLevel.status = "pending";
      state.updateCompanySettingsLevel.errorMessage = "";
    });
    builder.addCase(
      updateCompanySettingsLevel.rejected,
      (state, action: any) => {
        if (action?.updateCompanySettingsLevel?.status === 429) {
          state.updateCompanySettingsLevel.status = "error";
        } else {
          state.updateCompanySettingsLevel.status = "error";
          state.updateCompanySettingsLevel.errorMessage =
            action.payload ?? "An error occurred";
        }
      }
    );
    builder.addCase(updateCompanySettingsLevel.fulfilled, (state) => {
      state.updateCompanySettingsLevel.status = "fulfilled";
      state.updateCompanySettingsLevel.errorMessage = "";
    });
    builder.addCase(getLocations.pending, (state) => {
      state.getLocations.status = "pending";
      state.getLocations.errorMessage = "";
    });
    builder.addCase(getLocations.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getLocations.status = "error";
      } else {
        state.getLocations.status = "error";
        state.getLocations.errorMessage = action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.getLocations.status = "idle";
      state.getLocations.errorMessage = "";
      const locations = action.payload ?? [];
      const newLocations: CompanyLocation[] = [];
      let workCenters: CompanyWorkCenter[] = [];
      locations.forEach((location: CompanyLocation) => {
        const newLocation = { ...location };
        workCenters = workCenters.concat(location.workCenters);
        // Check if daily report email time is set
        if (
          Object.prototype.hasOwnProperty.call(
            newLocation,
            "enableDailyReportEmailTime"
          )
        ) {
          newLocation.enableDailyReportEmailTime = !(
            newLocation.dailyReportEmailTime === "" ||
            !newLocation.dailyReportEmailTime
          );
        }
        // Check if daily sms time is set
        if (
          Object.prototype.hasOwnProperty.call(
            newLocation,
            "dailySMSReminderTime"
          )
        ) {
          newLocation.enableDailySMSReminderTime = !(
            newLocation.dailySMSReminderTime === "" ||
            !newLocation.dailySMSReminderTime
          );
        }
        newLocations.push(newLocation);
      });

      state.locations = newLocations ?? [];

      // Add location prefix to work center
      const newWorkCenters = workCenters.map((workCenter) => {
        return {
          ...workCenter,
          name: `${locationOfWorkcenter(workCenter.id, newLocations)} - ${
            workCenter.name
          }`,
        };
      });

      state.allCompanyWorkCenters = newWorkCenters;
    });
    builder.addCase(getCompanyUsers.pending, (state) => {
      state.getCompanyUsers.status = "pending";
      state.getCompanyUsers.errorMessage = "";
    });
    builder.addCase(getCompanyUsers.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getCompanyUsers.status = "error";
      } else {
        state.getCompanyUsers.status = "error";
        state.getCompanyUsers.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.getCompanyUsers.status = "idle";
      state.getCompanyUsers.errorMessage = "";

      const locations = JSON.parse(JSON.stringify(state.locations)) ?? [];

      if (locations.length !== 0) {
        // Add location prefix to work center
        const companyUsers = Array.isArray(action.payload)
          ? action.payload
          : [];
        const newCompanyUsers: CompanyUser[] = [];
        companyUsers.forEach((user: CompanyUser) => {
          const newUser = { ...user };
          if (newUser.userWorkCenters) {
            newUser.userWorkCenters = newUser.userWorkCenters.map(
              (workCenter) => {
                return {
                  ...workCenter,
                  name: `${locationOfWorkcenter(workCenter.id, locations)} - ${
                    workCenter.name
                  }`,
                };
              }
            );
          }
          newCompanyUsers.push(newUser);
        });
        state.companyUsers = newCompanyUsers;
      } else {
        state.companyUsers = action.payload ?? [];
      }
    });
    builder.addCase(createNewCompany.pending, (state) => {
      state.createNewCompany.status = "pending";
      state.createNewCompany.errorMessage = "";
    });
    builder.addCase(createNewCompany.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createNewCompany.status = "error";
      } else {
        state.createNewCompany.status = "error";
        state.createNewCompany.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createNewCompany.fulfilled, (state, action) => {
      state.createNewCompany.status = "fulfilled";
      state.createNewCompany.errorMessage = "";
      state.createNewCompany.newCompanyId = action.payload.id;
      state.currentCompanyData = action.payload;
    });
    builder.addCase(getAllTimeZones.pending, (state) => {
      state.getAllTimeZones.status = "pending";
      state.getAllTimeZones.errorMessage = "";
    });
    builder.addCase(getAllTimeZones.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllTimeZones.status = "error";
      } else {
        state.getAllTimeZones.status = "error";
        state.getAllTimeZones.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllTimeZones.fulfilled, (state, action) => {
      state.getAllTimeZones.status = "idle";
      state.getAllTimeZones.errorMessage = "";
      state.timeZones = action.payload ?? [];
    });
    builder.addCase(createNewLocation.pending, (state) => {
      state.createNewLocation.status = "pending";
      state.createNewLocation.errorMessage = "";
    });
    builder.addCase(createNewLocation.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createNewLocation.status = "error";
      } else {
        state.createNewLocation.status = "error";
        state.createNewLocation.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createNewLocation.fulfilled, (state, action) => {
      state.createNewLocation.status = "fulfilled";
      state.createNewLocation.errorMessage = "";
      state.createNewLocation.locationId = action.payload.locationId;
      state.currentLocationData = action.payload;
    });
    builder.addCase(updateLocationData.pending, (state) => {
      state.updateLocationData.status = "pending";
      state.updateLocationData.errorMessage = "";
    });
    builder.addCase(updateLocationData.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.updateLocationData.status = "error";
      } else {
        state.updateLocationData.status = "error";
        state.updateLocationData.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(updateLocationData.fulfilled, (state) => {
      state.updateLocationData.status = "fulfilled";
      state.updateLocationData.errorMessage = "";
    });
    builder.addCase(createCompanyUser.pending, (state) => {
      state.createCompanyUser.status = "pending";
      state.createCompanyUser.errorMessage = "";
    });
    builder.addCase(createCompanyUser.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createCompanyUser.status = "error";
      } else {
        state.createCompanyUser.status = "error";
        state.createCompanyUser.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createCompanyUser.fulfilled, (state) => {
      state.createCompanyUser.status = "fulfilled";
      state.createCompanyUser.errorMessage = "";
    });
    builder.addCase(updateCompanyUser.pending, (state) => {
      state.updateCompanyUser.status = "pending";
      state.updateCompanyUser.errorMessage = "";
    });
    builder.addCase(updateCompanyUser.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.updateCompanyUser.status = "error";
      } else {
        state.updateCompanyUser.status = "error";
        state.updateCompanyUser.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(updateCompanyUser.fulfilled, (state) => {
      state.updateCompanyUser.status = "fulfilled";
      state.updateCompanyUser.errorMessage = "";
    });
    builder.addCase(getRolesForAdmin.pending, (state) => {
      state.getRolesForAdmin.status = "pending";
      state.getRolesForAdmin.errorMessage = "";
    });
    builder.addCase(getRolesForAdmin.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getRolesForAdmin.status = "error";
      } else {
        state.getRolesForAdmin.status = "error";
        state.getRolesForAdmin.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getRolesForAdmin.fulfilled, (state, action) => {
      state.getRolesForAdmin.status = "idle";
      state.getRolesForAdmin.errorMessage = "";
      state.allCompanyUserRoles = action.payload ?? [];
    });
    builder.addCase(createNewWorkCenter.pending, (state) => {
      state.createNewWorkCenter.status = "pending";
      state.createNewWorkCenter.errorMessage = "";
    });
    builder.addCase(createNewWorkCenter.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createNewWorkCenter.status = "error";
      } else {
        state.createNewWorkCenter.status = "error";
        state.createNewWorkCenter.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createNewWorkCenter.fulfilled, (state) => {
      state.createNewWorkCenter.status = "fulfilled";
      state.createNewWorkCenter.errorMessage = "";
    });
    builder.addCase(updateWorkCenterData.pending, (state) => {
      state.updateWorkCenterData.status = "pending";
      state.updateWorkCenterData.errorMessage = "";
    });
    builder.addCase(updateWorkCenterData.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.updateWorkCenterData.status = "error";
      } else {
        state.updateWorkCenterData.status = "error";
        state.updateWorkCenterData.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(updateWorkCenterData.fulfilled, (state) => {
      state.updateWorkCenterData.status = "fulfilled";
      state.updateWorkCenterData.errorMessage = "";
    });
    builder.addCase(getWorkerTypes.pending, (state) => {
      state.getWorkerTypes.status = "pending";
      state.getWorkerTypes.errorMessage = "";
    });
    builder.addCase(getWorkerTypes.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getWorkerTypes.status = "error";
      } else {
        state.getWorkerTypes.status = "error";
        state.getWorkerTypes.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getWorkerTypes.fulfilled, (state, action) => {
      state.getWorkerTypes.status = "fulfilled";
      state.getWorkerTypes.errorMessage = "";
      state.workerTypes = action.payload ?? [];
    });
    //getAllShiftAllocationRules
    builder.addCase(getAllShiftAllocationRules.pending, (state) => {
      state.getAllShiftAllocationRules.status = "pending";
      state.getAllShiftAllocationRules.errorMessage = "";
    });
    builder.addCase(
      getAllShiftAllocationRules.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.getAllShiftAllocationRules.status = "error";
        } else {
          state.getAllShiftAllocationRules.status = "error";
          state.getAllShiftAllocationRules.errorMessage =
            action.payload ?? "An error occurred";
        }
      }
    );
    builder.addCase(getAllShiftAllocationRules.fulfilled, (state, action) => {
      state.getAllShiftAllocationRules.status = "fulfilled";
      state.getAllShiftAllocationRules.errorMessage = "";
      state.getAllShiftAllocationRules.allocationTypes = action.payload.map(
        (allocationType: ShiftAllocationRulesAllocationType) => {
          return {
            ...allocationType,
            shiftAllocationTypeId: allocationType.id,
            urgentWindowSizeMins: 0,
            defaultWindowSizeMins: 0,
            tieBreakerProp: "rscore",
            jobIds: null,
          };
        }
      );
    });

    //getAllJobsByLocation
    builder.addCase(getAllJobsByLocation.pending, (state) => {
      state.getAllJobsByLocation.status = "pending";
      state.getAllJobsByLocation.errorMessage = "";
    });
    builder.addCase(getAllJobsByLocation.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllJobsByLocation.status = "error";
      } else {
        state.getAllJobsByLocation.status = "error";
        state.getAllJobsByLocation.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(
      getAllJobsByLocation.fulfilled,
      (state: GATAdminState, action) => {
        state.getAllJobsByLocation.status = "fulfilled";
        state.getAllJobsByLocation.errorMessage = "";
        state.getAllJobsByLocation.jobs = action.payload ?? [];
        state.getAllJobsByLocation.jobsObject = action.payload?.reduce(
          (acc: any, job: any) => {
            acc[job.id] = job;
            return acc;
          },
          {}
        );
      }
    );

    //getAllShiftTypes
    builder.addCase(getAllShiftTypes.pending, (state) => {
      state.getAllShiftTypes.status = "pending";
      state.getAllShiftTypes.errorMessage = "";
    });
    builder.addCase(getAllShiftTypes.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllShiftTypes.status = "error";
      } else {
        state.getAllShiftTypes.status = "error";
        state.getAllShiftTypes.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllShiftTypes.fulfilled, (state, action) => {
      state.getAllShiftTypes.status = "fulfilled";
      state.getAllShiftTypes.errorMessage = "";
      state.getAllShiftTypes.data = action.payload ?? [];
    });

    //createNewSchedule
    builder.addCase(createNewSchedule.pending, (state) => {
      state.createNewSchedule.status = "pending";
      state.createNewSchedule.errorMessage = "";
    });
    builder.addCase(createNewSchedule.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createNewSchedule.status = "error";
      } else {
        state.createNewSchedule.status = "error";
        state.createNewSchedule.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createNewSchedule.fulfilled, (state) => {
      state.createNewSchedule.status = "fulfilled";
      state.createNewSchedule.errorMessage = "";
    });

    //editShiftSchedule
    builder.addCase(editShiftSchedule.pending, (state) => {
      state.editShiftSchedule.status = "pending";
      state.editShiftSchedule.errorMessage = "";
    });
    builder.addCase(editShiftSchedule.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.editShiftSchedule.status = "error";
      } else {
        state.editShiftSchedule.status = "error";
        state.editShiftSchedule.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(editShiftSchedule.fulfilled, (state) => {
      state.editShiftSchedule.status = "fulfilled";
      state.editShiftSchedule.errorMessage = "";
      state.editShiftSchedule.isDialogOpen = false;
    });

    //deleteShiftSchedule
    builder.addCase(deleteShiftSchedule.pending, (state) => {
      state.deleteShiftSchedule.status = "pending";
      state.deleteShiftSchedule.errorMessage = "";
    });
    builder.addCase(deleteShiftSchedule.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.deleteShiftSchedule.status = "error";
      } else {
        state.deleteShiftSchedule.status = "error";
        state.deleteShiftSchedule.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(
      deleteShiftSchedule.fulfilled,
      (state: GATAdminState, action) => {
        state.deleteShiftSchedule.status = "fulfilled";
        state.deleteShiftSchedule.errorMessage = "";
        const temp = state.getAllShiftTypes.data.filter(
          (shift: ShiftType) => shift.id !== action.payload
        );
        state.getAllShiftTypes.data = temp;
      }
    );

    builder.addCase(createLocationPool.pending, (state) => {
      state.createLocationPool.status = "pending";
      state.createLocationPool.errorMessage = "";
    });
    builder.addCase(createLocationPool.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createLocationPool.status = "error";
      } else {
        state.createLocationPool.status = "error";
        state.createLocationPool.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createLocationPool.fulfilled, (state, action) => {
      state.createLocationPool.status = "fulfilled";
      state.createLocationPool.errorMessage = "";
    });

    builder.addCase(editLocationPool.pending, (state) => {
      state.editLocationPool.status = "pending";
      state.editLocationPool.errorMessage = "";
    });
    builder.addCase(editLocationPool.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.editLocationPool.status = "error";
      } else {
        state.editLocationPool.status = "error";
        state.editLocationPool.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(editLocationPool.fulfilled, (state, action) => {
      state.editLocationPool.status = "fulfilled";
      state.editLocationPool.errorMessage = "";
    });

    builder.addCase(deleteLocationPool.pending, (state) => {
      state.deleteLocationPool.status = "pending";
      state.deleteLocationPool.errorMessage = "";
    });
    builder.addCase(deleteLocationPool.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.deleteLocationPool.status = "error";
      } else {
        state.deleteLocationPool.status = "error";
        state.deleteLocationPool.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(deleteLocationPool.fulfilled, (state, action) => {
      state.deleteLocationPool.status = "fulfilled";
      state.deleteLocationPool.errorMessage = "";
    });
    builder.addCase(getAllTeamsByLocation.pending, (state) => {
      state.getAllTeamsByLocation.status = "pending";
      state.getAllTeamsByLocation.errorMessage = "";
    });
    builder.addCase(getAllTeamsByLocation.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllTeamsByLocation.status = "error";
      } else {
        state.getAllTeamsByLocation.status = "error";
        state.getAllTeamsByLocation.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllTeamsByLocation.fulfilled, (state, action) => {
      state.getAllTeamsByLocation.status = "fulfilled";
      state.getAllTeamsByLocation.errorMessage = "";
      state.teams = action.payload;
    });

    //getAllManagers
    builder.addCase(getAllManagers.pending, (state) => {
      state.getAllManagers.status = "pending";
      state.getAllManagers.errorMessage = "";
    });
    builder.addCase(getAllManagers.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getAllManagers.status = "error";
      } else {
        state.getAllManagers.status = "error";
        state.getAllManagers.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getAllManagers.fulfilled, (state, action) => {
      state.getAllManagers.status = "fulfilled";
      state.getAllManagers.errorMessage = "";
      state.getAllManagers.data = action.payload ?? [];
    });

    //createNewTeam
    builder.addCase(createNewTeam.pending, (state) => {
      state.createNewTeam.status = "pending";
      state.createNewTeam.errorMessage = "";
    });
    builder.addCase(createNewTeam.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createNewTeam.status = "error";
      } else {
        state.createNewTeam.status = "error";
        state.createNewTeam.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(createNewTeam.fulfilled, (state, action) => {
      state.createNewTeam.status = "fulfilled";
      state.createNewTeam.errorMessage = "";
    });

    //editTeam
    builder.addCase(editTeam.pending, (state) => {
      state.editTeam.status = "pending";
      state.editTeam.errorMessage = "";
    });
    builder.addCase(editTeam.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.editTeam.status = "error";
      } else {
        state.editTeam.status = "error";
        state.editTeam.errorMessage = action.payload ?? "An error occurred";
      }
    });
    builder.addCase(editTeam.fulfilled, (state) => {
      state.editTeam.status = "fulfilled";
      state.editTeam.errorMessage = "";
    });

    // removeShiftPattern
    builder.addCase(removeShiftPattern.pending, (state) => {
      state.removeShiftPattern.status = "pending";
      state.removeShiftPattern.errorMessage = "";
    });
    builder.addCase(removeShiftPattern.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.removeShiftPattern.status = "error";
      } else {
        state.removeShiftPattern.status = "error";
        state.removeShiftPattern.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(removeShiftPattern.fulfilled, (state, action: any) => {
      state.removeShiftPattern.status = "fulfilled";
      state.removeShiftPattern.errorMessage = "";
      const tempTeams = [...state.teams];
      state.teams = tempTeams.filter(
        (team: Team) => team.id !== action.payload
      );
    });

    //getCompanyManagers
    builder.addCase(getCompanyManagers.pending, (state) => {
      state.getCompanyManagers.status = "pending";
      state.getCompanyManagers.errorMessage = "";
    });
    builder.addCase(getCompanyManagers.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getCompanyManagers.status = "error";
      } else {
        state.getCompanyManagers.status = "error";
        state.getCompanyManagers.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(getCompanyManagers.fulfilled, (state, action) => {
      state.getCompanyManagers.status = "fulfilled";
      state.getCompanyManagers.errorMessage = "";
      state.getCompanyManagers.data = action.payload ?? [];
    });
    builder.addCase(getSkillPriorities.pending, (state) => {
      state.getSkillPriorities.status = "pending";
    });
    builder.addCase(getSkillPriorities.fulfilled, (state, action) => {
      state.getSkillPriorities.status = "fulfilled";
      state.skillPriorities = action.payload ?? [];
    });
    builder.addCase(getSkillPriorities.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getSkillPriorities.status = "error";
      } else {
        state.getSkillPriorities.status = "error";
      }
    });
    //change user status
    builder.addCase(changeUserStatus.pending, (state) => {
      state.changeUserStatus.status = "pending";
      state.changeUserStatus.errorMessage = "";
    });
    builder.addCase(changeUserStatus.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.changeUserStatus.status = "error";
      } else {
        state.changeUserStatus.status = "error";
        state.changeUserStatus.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
    builder.addCase(changeUserStatus.fulfilled, (state, action) => {
      state.changeUserStatus.status = "fulfilled";
      state.changeUserStatus.errorMessage = "";
      const allUsers = state.companyUsers;

      const currentUser = allUsers.find(
        (user: CompanyUser) => user.id === action.payload.userId
      );

      const currentUserCount = currentUser?.allAssignedWorkersCount ?? 0;

      state.companyUsers = state.companyUsers.map((user: CompanyUser) => {
        if (user.id === action.payload.userId) {
          return {
            ...user,
            isActive: !user.isActive,
            allAssignedWorkersCount: 0,
          };
        } else if (user.id === action.payload.alternateSupervisorId) {
          return {
            ...user,
            allAssignedWorkersCount:
              user.allAssignedWorkersCount + currentUserCount,
          };
        } else {
          return user;
        }
      });
    });

    builder.addCase(getWorkerNotificationTypes.pending, (state) => {
      state.getWorkerNotificationTypes.status = "pending";
    });
    builder.addCase(getWorkerNotificationTypes.fulfilled, (state, action) => {
      state.getWorkerNotificationTypes.status = "fulfilled";
      state.getWorkerNotificationTypes.data = action.payload ?? [];
    });
    builder.addCase(
      getWorkerNotificationTypes.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.getWorkerNotificationTypes.status = "error";
        } else {
          state.getWorkerNotificationTypes.status = "error";
        }
      }
    );

    builder.addCase(updateWorkerNotificationTypes.pending, (state) => {
      state.updateCompanySettings.status = "pending";
      state.updateCompanySettings.errorMessage = "";
    });
    builder.addCase(
      updateWorkerNotificationTypes.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.updateCompanySettings.status = "error";
        } else {
          state.updateCompanySettings.status = "error";
          state.updateCompanySettings.errorMessage =
            action.payload ?? "An error occurred";
        }
      }
    );
    builder.addCase(updateWorkerNotificationTypes.fulfilled, (state) => {
      state.updateCompanySettings.status = "fulfilled";
      state.updateCompanySettings.errorMessage = "";
    });

    builder.addCase(getLeaveTypes.pending, (state) => {
      state.getLeaveTypes.status = "pending";
    });
    builder.addCase(getLeaveTypes.fulfilled, (state, action) => {
      state.getLeaveTypes.status = "fulfilled";
      state.getLeaveTypes.data = action.payload ?? [];
    });

    builder.addCase(getLeaveTypes.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getLeaveTypes.status = "error";
      } else {
        state.getLeaveTypes.status = "error";
      }
    });

    builder.addCase(createTimeOffType.pending, (state) => {
      state.createTimeOffType.status = "pending";
    });
    builder.addCase(createTimeOffType.fulfilled, (state, action) => {
      const locations = action.payload.enabledLocations.map(
        (location: number) => state.locations.find((loc) => loc.id === location)
      );
      state.createTimeOffType.status = "fulfilled";
      state.getLeaveTypes.data = [
        ...state.getLeaveTypes.data,
        { ...action.payload, enabledLocations: locations },
      ];
    });
    builder.addCase(createTimeOffType.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.createTimeOffType.status = "error";
      } else {
        state.createTimeOffType.status = "error";
        state.createTimeOffType.errorMessage =
          (action.payload as string) ?? "An error occurred";
      }
    });

    builder.addCase(editTimeOffType.pending, (state) => {
      state.editTimeOffType.status = "pending";
    });
    builder.addCase(editTimeOffType.fulfilled, (state, action) => {
      state.editTimeOffType.status = "fulfilled";
      state.getLeaveTypes.data = state.getLeaveTypes.data.map(
        (leaveType: LeaveType) => {
          if (leaveType.id === action.payload.id) {
            return {
              ...leaveType,
              ...action.payload,
              enabledLocations: action.payload.locs,
            };
          } else {
            return leaveType;
          }
        }
      );
    });

    builder.addCase(editTimeOffType.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.editTimeOffType.status = "error";
      } else {
        state.editTimeOffType.status = "error";
        state.editTimeOffType.errorMessage =
          (action.payload as string) ?? "An error occurred";
      }
    });

    builder.addCase(saveSkillPriorities.pending, (state) => {
      state.saveSkillPriorities.status = "pending";
    });
    builder.addCase(saveSkillPriorities.fulfilled, (state) => {
      state.saveSkillPriorities.status = "fulfilled";
    });
    builder.addCase(saveSkillPriorities.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.saveSkillPriorities.status = "error";
      } else {
        state.saveSkillPriorities.status = "error";
        state.saveSkillPriorities.errorMessage =
          (action.payload as string) ?? "An error occurred";
      }
    });

    // deleteTimeOffType
    builder.addCase(deleteTimeOffType.pending, (state) => {
      state.deleteTimeOffType.status = "pending";
    });
    builder.addCase(deleteTimeOffType.fulfilled, (state, action: any) => {
      state.deleteTimeOffType.status = "fulfilled";
      state.getLeaveTypes.data = state.getLeaveTypes.data.filter(
        (leaveType: { id: number }) => leaveType.id !== action.payload
      );
    });
    builder.addCase(deleteTimeOffType.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.deleteTimeOffType.status = "error";
      } else {
        state.deleteTimeOffType.status = "error";
        state.deleteTimeOffType.errorMessage =
          (action.payload as string) ?? "An error occurred";
      }
    });
    //updateUserRoleToDemoResetRole
    builder.addCase(updateUserRoleToDemoResetRole.pending, (state) => {
      state.updateUserRoleToDemoResetRole.status = "pending";
      state.updateUserRoleToDemoResetRole.errorMessage = "";
    });
    builder.addCase(
      updateUserRoleToDemoResetRole.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.updateUserRoleToDemoResetRole.status = "error";
        } else {
          state.updateUserRoleToDemoResetRole.status = "error";
          state.updateUserRoleToDemoResetRole.errorMessage =
            action.payload ?? "An error occurred";
        }
      }
    );
    builder.addCase(
      updateUserRoleToDemoResetRole.fulfilled,
      (state, action) => {
        state.updateUserRoleToDemoResetRole.status = "fulfilled";
        state.openSetUpDemoOwnerDialog = false;
      }
    );
    builder.addCase(getCompanyNotAssignedSkillLevel.pending, (state) => {
      state.getCompanyNotAssignedSkillLevel.status = "pending";
    });
    builder.addCase(
      getCompanyNotAssignedSkillLevel.fulfilled,
      (state, action) => {
        state.getCompanyNotAssignedSkillLevel.status = "fulfilled";
        state.companyNotAssignedSkillLevel = action.payload ?? null;
      }
    );
    builder.addCase(
      getCompanyNotAssignedSkillLevel.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.getCompanyNotAssignedSkillLevel.status = "error";
        } else {
          state.getCompanyNotAssignedSkillLevel.status = "error";
        }
      }
    );
    builder.addCase(updateCompanyNotAssignedSkillLevel.pending, (state) => {
      state.updateCompanyNotAssignedSkillLevel.status = "pending";
    });
    builder.addCase(
      updateCompanyNotAssignedSkillLevel.fulfilled,
      (state, action) => {
        state.updateCompanyNotAssignedSkillLevel.status = "fulfilled";
        state.companyNotAssignedSkillLevel =
          action.payload.notAssignedSkillLevel ?? null;
      }
    );
    builder.addCase(
      updateCompanyNotAssignedSkillLevel.rejected,
      (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.updateCompanyNotAssignedSkillLevel.status = "error";
        } else {
          state.updateCompanyNotAssignedSkillLevel.status = "error";
        }
      }
    );
  },
});

export const {
  resetCreateNewCompany,
  resetCreateNewLocation,
  resetUpdateCompanyData,
  resetUpdateCompanySettings,
  resetUpdateCompanySettingsLevel,
  resetUpdateLocationData,
  resetCompanySettings,
  resetCompanyData,
  resetLocations,
  resetCompanyUsers,
  resetCreateCompanyUser,
  resetUpdateCompanyUser,
  resetCreateNewWorkCenter,
  resetUpdateWorkCenterData,
  setLocationDialog,
  setLocationClusterDialog,
  setCrossSkillingPrioritiesDialog,
  setWorkCenterDialog,
  setCompanyUserDialog,
  setDemoSetupOwnerDialog,
  setCrossSkillingLevelsDialog,
  resetWorkerTypes,
  resetCreateNewSchedule,
  setDefaultShiftAllocationRules,
  openEditShiftScheduleDialog,
  closeEditShiftScheduleDialog,
  resetEditShiftScheduleDialog,
  resetDeleteShiftSchedule,
  updateCreateNewCompany,
  updateCreateNewLocation,
  setCurrentLocationData,
  resetCurrentLocationData,
  setCurrentCompanyData,
  resetCurrentCompanyData,
  resetEditLocationPool,
  resetCreateLocationPool,
  resetDeleteLocationPool,
  resetCompanyLocationClusters,
  resetCreateNewTeam,
  resetEditTeam,
  resetRemoveShiftPattern,
  openEditTeamDialog,
  closeEditTeamDialog,
  openUserDeactivateDialog,
  closeUserDeactivateDialog,
  resetUserStatus,
  resetCreateTimeOffType,
  resetEditTimeOffType,
  resetSaveSkillPriorities,
  resetDeleteTimeOffType,
} = GATAdminConfigurationSlice.actions;

export default GATAdminConfigurationSlice.reducer;
