import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import english from "./locales/english.json";
import french from "./locales/french.json";
import spanish from "./locales/spanish.json";

const getDeviceLanguage = () => {
  const savedLanguage = localStorage.getItem("worker-language");
  if (savedLanguage) {
    return savedLanguage;
  }
  return "en";
};

export const initializeI18n = () => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      compatibilityJSON: "v3",
      resources: {
        en: { translation: english },
        fr: { translation: french },
        es: { translation: spanish },
      },
      lng: getDeviceLanguage(),
      fallbackLng: {
        // Fallback rules
        fr: ["fr"],
        "fr-*": ["fr"], // All fr-XX variations fall back to fr
        es: ["es"],
        "es-*": ["es"], // All es-XX variations fall back to es
        default: ["en"], // Any other language falls back to en
      },
      interpolation: {
        escapeValue: false,
      },
    });
};

initializeI18n();

export default i18n;
