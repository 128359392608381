import makeStyles from "@mui/styles/makeStyles";
import { addDays, getDay, isValid, parseISO, startOfDay } from "date-fns";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import * as Yup from "yup";

import { generateDaysOfTheWeek } from "../../../../../utils/helpers";

interface AppliedFilter {
	type: string;
	timeAdded: number;
	data: Array<{
		id: string | number;
		name: string;
		timeAdded: number;
	}>;
}

interface WeekDayDates {
	[key: string]: Date;
}

export const useStyles = makeStyles(() => ({
	cancelButton: {
		marginRight: "auto",
	},
	dialogContentRoot: {
		maxWidth: "70%",
	},
	formControlRoot: {
		width: "100%",
		"& .MuiFilledInput-root": {
			background: "#F8F8F8 !important",
		},
	},
	btnLabel: {
		position: "relative",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	formRoot: {
		width: "100%",
	},
}));

const toLocalISOString = (date: Date) => {
	const off = date.getTimezoneOffset();
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes() - off,
		date.getSeconds(),
		date.getMilliseconds()
	).toISOString();
};

export const setToNearestTime = (dateString: string) => {
	const date = new Date(dateString);
	return toLocalISOString(date).slice(0, 16);
};

function getWeekDaysFromStartDay(startDay: string): string[] {
	const weekDays = [
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday",
	];

	const normalizedStartDay = startDay.toLowerCase();

	const startIndex = weekDays.indexOf(normalizedStartDay);

	if (startIndex === -1) {
		throw new Error("Invalid weekday provided");
	}

	return [...weekDays.slice(startIndex), ...weekDays.slice(0, startIndex)];
}

function getWeekDayDates(date: string | Date, startDay: string): WeekDayDates {
	// Map day names to date-fns day numbers (0-6, where 0 is Sunday)
	const dayMapping: { [key: string]: number } = {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	};

	// Convert input date to Date object if it's a string
	const inputDate = typeof date === "string" ? parseISO(date) : date;

	// Validate date
	if (!isValid(inputDate)) {
		throw new Error("Invalid date provided");
	}

	// Validate start day
	const normalizedStartDay = startDay.toLowerCase();
	if (!(normalizedStartDay in dayMapping)) {
		throw new Error("Invalid weekday provided");
	}

	// Get ordered week days starting from the given day
	const weekDays = getWeekDaysFromStartDay(startDay);

	// Calculate the start of the week based on the input date and start day
	const currentDayNum = getDay(inputDate);
	const targetDayNum = dayMapping[normalizedStartDay];
	const daysToSubtract = (currentDayNum - targetDayNum + 7) % 7;
	const weekStartDate = startOfDay(addDays(inputDate, -daysToSubtract));

	// Create the mapping of days to dates
	return weekDays.reduce((acc, day, index) => {
		const currentDate = addDays(weekStartDate, index);
		acc[day] = new Date(currentDate);
		return acc;
	}, {} as WeekDayDates);
}

export const configureDatesOfTheWeek = (
	date: Date,
	weekStartDay: string
): Record<string, Date> => {
	const currentWeekDates = getWeekDayDates(date, weekStartDay.toLowerCase());
	return currentWeekDates;
};

export const yupInitObject = {
	startTime: Yup.string()
		.required("Start Time cannot be empty")
		.test(
			"is-greater",
			"Start Time should be greater than current time",
			function (value: string | undefined) {
				return value ? isAfter(new Date(value), new Date()) : !!value;
			}
		),
	endTime: Yup.string()
		.required("End Time cannot be empty")
		.test(
			"is-greater",
			"End Time should be greater than Start Time",
			function (value: string | undefined) {
				const { startTime } = this.parent || {};
				return value ? isAfter(new Date(value), new Date(startTime)) : !!value;
			}
		),
	workCenterId: Yup.string().required("Workcenter is required"),
	//jobId: Yup.string().required("Job Role is required"),
	numberOfWorkersNeeded: Yup.number()
		.moreThan(0, "Minimum worker required is 1")
		.lessThan(100000, "Maximum worker required should be less than 100,000")
		.required("Number of Staff is required"),
	shiftPremium: Yup.string(),
	repeatShift: Yup.boolean(),
	shiftNote: Yup.string().max(250, "Note should be less than 250 characters"),
	repeatOn: Yup.object({
		0: Yup.boolean(),
		1: Yup.boolean(),
		2: Yup.boolean(),
		3: Yup.boolean(),
		4: Yup.boolean(),
		5: Yup.boolean(),
		6: Yup.boolean(),
	}).test(
		"at-least-one-day-selected",
		"At least select one day",
		function (value: any) {
			const { repeatShift } = this.parent || {};
			// If repeatShift toggle is false, we do not validate days at all
			if (!repeatShift) return true;
			// If repeatShift toggle is true then we validate that at least one day is true
			else {
				const flag = !!(
					value &&
					(value[0] ||
						value[1] ||
						value[2] ||
						value[3] ||
						value[4] ||
						value[5] ||
						value[6])
				);
				return flag;
			}
		}
	),
};

type Skill = {
	skillId: string;
	skillLevelId: string;
	skillName: string;
	level: number;
	id: string;
	name: string;
};
interface InitValues {
	startTime: string;
	endTime: string;
	jobId: string;
	workCenterId: string;
	numberOfWorkersNeeded: string;
	shiftPremium: string;
	repeatShift: boolean;
	repeatOn: Record<number, boolean>;
	locationId: string;
	shiftSkills: Skill[] | [];
	hexColor: string;
	shiftNote: string;
	shiftPurposeId: number | string;
	repeatEndDate: string;
	endType: "OnDate" | "AfterOccurrences";
	occurrences: number | null;
}

export const getInitValues = ({
	draftShiftDate,
	defaultDurationHrs,
	shiftPurposeId,
	weekStartDay,
}: {
	draftShiftDate: Date;
	defaultDurationHrs?: number;
	shiftPurposeId: number;
	weekStartDay: string;
}): InitValues => {
	const shiftDay: number = draftShiftDate.getDay();
	const initDays: Record<number, boolean> = {
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		[shiftDay]: true,
	};
	const weekEnd = generateDaysOfTheWeek(draftShiftDate, weekStartDay);

	return {
		startTime:
			format(draftShiftDate, "yyyy-MM-dd") +
			"T" +
			format(draftShiftDate, "HH:mm"),
		endTime:
			format(addHours(draftShiftDate, defaultDurationHrs || 2), "yyyy-MM-dd") +
			"T" +
			format(addHours(draftShiftDate, defaultDurationHrs || 2), "HH:mm"),
		jobId: "",
		workCenterId: "",
		locationId: "",
		numberOfWorkersNeeded: "",
		shiftPremium: "",
		repeatShift: false,
		shiftSkills: [],
		repeatOn: { ...initDays },
		hexColor: "",
		shiftNote: "",
		shiftPurposeId: shiftPurposeId === 0 ? "" : shiftPurposeId,
		repeatEndDate: format(weekEnd.sunday, "yyyy-MM-dd"),
		endType: "OnDate",
		occurrences: null,
	};
};

export function getDaysOfWeek(repeatOn: { [key: string]: boolean }): string {
	const daysOfWeek = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	return Object.entries(repeatOn)
		.filter(([_, isSelected]) => isSelected)
		.map(([dayIndex]) => daysOfWeek[parseInt(dayIndex)])
		.join(",");
}
