import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { closeReviewPublishShiftDialog,openReviewPublishShiftDialog, resetBroadcastMessageState } from "../store/scheduler-slice";
import useSelectorData from "./use-scheduler-data";

const useScheduleHeader = () => {
	const dispatch = useDispatch();
	const {
		publishDraftShiftsAsyncStatus,
		broadcastMessageStatus,
		isEditShiftDialogOpen,
		isReviewPublishingShiftsDialogOpen,
	} = useSelectorData();

	// const [
	// 	isReviewPublishingShiftsDialogOpen,
	// 	setIsReviewPublishingShiftsDialogOpen,
	// ] = useState(false);
	const [isBMessageDialogOpen, setIsBMessageDialogOpen] = useState(false);

	useEffect(() => {
		if (publishDraftShiftsAsyncStatus === "fulfilled")
			dispatch(closeReviewPublishShiftDialog());
	}, [dispatch, publishDraftShiftsAsyncStatus]);

	useEffect(() => {
		if (broadcastMessageStatus === "fulfilled") {
			setIsBMessageDialogOpen(false);
		}
	}, [broadcastMessageStatus]);

	const openBMessageDialog = () => {
		setIsBMessageDialogOpen(true);
		dispatch(resetBroadcastMessageState());
	};

	const closeBMessageDialog = () => {
		setIsBMessageDialogOpen(false);
	};

	return [
		{
			isReviewPublishingShiftsDialogOpen,
			isBMessageDialogOpen,
			isEditShiftDialogOpen,
		},
		{
			openReviewPublishShiftDialog,
			closeReviewPublishShiftDialog,
			closeBMessageDialog,
			openBMessageDialog,
		},
	];
};

export default useScheduleHeader;
