import { Box, Switch, Typography } from "@mui/material";
import React from "react";

const SkillLevelJobMapping = ({
	newAddOnSettings,
	setNewAddOnSettings,
}: {
	newAddOnSettings: any;
	setNewAddOnSettings: any;
}) => {
	return (
		<Box
			sx={{
				marginTop: "20px",
				marginBottom: "8px",
				borderRadius: "8px",
				border: 1,
				borderWidth: "1px",
				borderColor: "rgba(0,0,0,0.12)",
				background: "#FFF",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0px 16px",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Enable Skill Level configuration for Job-Skill mapping
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Define Skill Level requirement for Skills on each Job
					</Typography>
				</Box>
				<Switch
					checked={newAddOnSettings.skillLevelJobMappingEnabled ?? false}
					disabled={!newAddOnSettings.crossSkillingEnabled}
					onChange={(e) => {
						setNewAddOnSettings((pre: any) => {
							return {
								...pre,
								skillLevelJobMappingEnabled: e.target.checked,
							};
						});
					}}
				/>
			</Box>
		</Box>
	);
};

export default SkillLevelJobMapping;
