import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../utils/api-client";
import { setSupervisorRateLimitError } from "../../user/user-slice";
import {
  CertificationsState,
  CreateCertification_Payload,
} from "./certifications-types";

const initialState: CertificationsState = {
  masterData: {
    status: "idle",
    error: "",
    allCertifications: [],
    skills: [],
    certTypes: [],
  },
  editDialog: {
    isOpen: false,
    data: null,
  },
  createNewCertification: {
    status: "idle",
    errorMessage: "",
  },
  editCertification: {
    status: "idle",
    errorMessage: "",
  },
};

export const fetchInitialCertificationsData = createAsyncThunk(
  "certifications/fetchInitialCertificationsData",
  async (payload, thunkApi) => {
    try {
      const getCertificates = axios.get(
        `${process.env.REACT_APP_API_END_POINT}/Company/Certs`
      );

      const getCertificateTypes = axios.get(
        `${process.env.REACT_APP_API_END_POINT}/company/certtypes`
      );

      const [certificates, certTypes] = await Promise.all([
        getCertificates,
        getCertificateTypes,
      ]);
      const result = {
        certificates: certificates.data,
        certTypes: certTypes.data,
      };
      return thunkApi.fulfillWithValue(result);
    } catch (error: any) {
      if (error?.response?.status === 429) {
        thunkApi.dispatch(setSupervisorRateLimitError());
      }
      const errorResponse = error.response.data;
      errorResponse.status = error?.response?.status;
      return thunkApi.rejectWithValue(errorResponse);
    }
  }
);

export const createNewCertification = createAsyncThunk(
  "certifications/createNewCertification",
  async (payload: CreateCertification_Payload, thunkApi) => {
    const {
      name,
      type,
      description,
      gracePeriod,
      period,
      periodType,
      isValidityPeriod,
      attachmentRequired,
    } = payload;
    const certData = {
      Name: name,
      CertTypeId: type,
      ValidityPeriod: isValidityPeriod ? period : null,
      ValidityPeriodUnit: periodType && isValidityPeriod ? periodType : null,
      AttachmentRequired: attachmentRequired,
      GracePeriod: isValidityPeriod && gracePeriod ? gracePeriod : null,
      GracePeriodUnit: isValidityPeriod && gracePeriod ? "d" : null,
      Description: description,
      IsObsolete: false,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/Company/Cert`,
        certData
      );
      return thunkApi.fulfillWithValue(response.data);
    } catch (error: any) {
      if (error?.response?.status === 429) {
        thunkApi.dispatch(setSupervisorRateLimitError());
      }
      const errorResponse = error.response.data;
      errorResponse.status = error?.response?.status;
      return thunkApi.rejectWithValue(errorResponse);
    }
  }
);

export const editCertification = createAsyncThunk(
  "certifications/editCertification",
  async (payload: CreateCertification_Payload, thunkApi) => {
    const {
      id,
      name,
      type,
      description,
      gracePeriod,
      period,
      periodType,
      isValidityPeriod,
      attachmentRequired,
      isActive,
    } = payload;
    const certData = {
      id: id,
      Name: name,
      CertTypeId: type,
      ValidityPeriod: isValidityPeriod ? period : null,
      ValidityPeriodUnit: periodType && isValidityPeriod ? periodType : null,
      AttachmentRequired: attachmentRequired,
      GracePeriod: isValidityPeriod && gracePeriod ? gracePeriod : null,
      GracePeriodUnit: isValidityPeriod && gracePeriod ? "d" : null,
      Description: description,
      IsObsolete: !isActive,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_END_POINT}/Company/Cert`,
        certData
      );
      return thunkApi.fulfillWithValue(response.data);
    } catch (error: any) {
      if (error?.response?.status === 429) {
        thunkApi.dispatch(setSupervisorRateLimitError());
      }
      const errorResponse = error.response.data;
      errorResponse.status = error?.response?.status;
      return thunkApi.rejectWithValue(errorResponse);
    }
  }
);

export const Certifications = createSlice({
  name: "certifications",
  initialState,
  reducers: {
    resetCertifications: (state) => {
      state.masterData = initialState.masterData;
    },
    openEditDialog: (state, action) => {
      state.editDialog.isOpen = true;
      state.editDialog.data = { ...action.payload };
    },
    closeEditDialog: (state) => {
      state.editDialog.isOpen = false;
      state.editDialog.data = null;
    },
    resetCreateNewCertification: (state) => {
      state.createNewCertification = initialState.createNewCertification;
    },
    resetEditCertification: (state) => {
      state.editCertification = initialState.editCertification;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialCertificationsData.pending, (state) => {
        state.masterData.status = "pending";
      })
      .addCase(fetchInitialCertificationsData.fulfilled, (state, action) => {
        state.masterData.status = "fulfilled";
        const { certificates, certTypes } = action.payload;
        const certs =
          certificates?.map((cert: { isObsolete: boolean }) => ({
            ...cert,
            isActive: !cert.isObsolete,
          })) ?? [];
        state.masterData.skills = [];
        state.masterData.allCertifications = certs ?? [];
        state.masterData.certTypes = certTypes ?? [];
      })
      .addCase(
        fetchInitialCertificationsData.rejected,
        (state, action: any) => {
          if (action?.payload?.status === 429) {
            state.masterData.status = "error";
          } else {
            state.masterData.status = "rejected";
            state.masterData.error = action.error.message ?? "";
          }
        }
      )
      // create new certification
      .addCase(createNewCertification.pending, (state) => {
        state.createNewCertification.status = "pending";
      })
      .addCase(createNewCertification.fulfilled, (state) => {
        state.createNewCertification.status = "fulfilled";
      })
      .addCase(createNewCertification.rejected, (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.createNewCertification.status = "error";
        } else {
          state.createNewCertification.status = "rejected";
          state.createNewCertification.errorMessage =
            (action.payload as any).message ?? "";
        }
      })
      // edit certification
      .addCase(editCertification.pending, (state) => {
        state.editCertification.status = "pending";
      })
      .addCase(editCertification.fulfilled, (state) => {
        state.editCertification.status = "fulfilled";
      })
      .addCase(editCertification.rejected, (state, action: any) => {
        if (action?.payload?.status === 429) {
          state.editCertification.status = "error";
        } else {
          state.editCertification.status = "rejected";
          state.editCertification.errorMessage =
            (action.payload as any).message ?? "";
        }
      });
  },
});

export const {
  resetCertifications,
  openEditDialog,
  closeEditDialog,
  resetCreateNewCertification,
  resetEditCertification,
} = Certifications.actions;

export default Certifications.reducer;
