import { Avatar, MenuItem } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
interface RepeatShiftProps {
	values: any;
	setFieldValue: any;
	selectedDatesOfTheWeek: any;
	width: number;
}

const RepeatShift = ({
	values,
	setFieldValue,
	selectedDatesOfTheWeek,
	width,
}: RepeatShiftProps) => {
	const trueValues = Object.values(values.repeatOn).filter(Boolean).length;

	return (
		<Box>
			<Typography
				variant='caption'
				sx={{ fontSize: "14px", marginLeft: "4px" }}
			>
				Repeat Weekly on
			</Typography>
			<Box
				display='flex'
				justifyContent='space-between'
				width='100%'
				flexGrow={1}
				flexWrap='wrap'
				sx={{
					opacity: `${values.repeatShift ? "" : "60%"}`,
				}}
			>
				{selectedDatesOfTheWeek &&
					Object.values(selectedDatesOfTheWeek).map((day: any) => {
						const currentDate: Date = new Date(day);
						const currentDay: number = currentDate.getDay();
						return (
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								key={day}
								borderRadius={2}
							>
								<IconButton
									onClick={() => {
										if (values.repeatShift) {
											setFieldValue(
												`repeatOn[${currentDay}]`,
												!values.repeatOn[currentDay]
											);
										}
									}}
								>
									<Avatar
										style={{
											backgroundColor: `${
												values.repeatOn[currentDay]
													? "#2F4D8B"
													: "rgba(0, 0, 0, 0.06)"
											}`,
											color: `${
												values.repeatOn[currentDay] ? "#fff" : "#666666"
											}`,
										}}
									>
										<Typography sx={{ fontSize: "1rem" }}>
											{format(currentDate, "EEEEEE")}
										</Typography>
									</Avatar>
								</IconButton>
							</Box>
						);
					})}
				{trueValues === 0 && (
					<Typography
						variant='caption'
						color='error'
						sx={{ fontSize: "14px", marginLeft: "4px" }}
					>
						Please select at least one day of the week
					</Typography>
				)}
			</Box>
			<Typography
				sx={{
					fontSize: "16px",
					fontWeight: 400,
					fontFamily: "Roboto",
					marginLeft: "4px",
					lineHeight: "24px",
				}}
			>
				After/On
			</Typography>
			<Box
				display='flex'
				justifyContent='space-between'
				flexDirection={width < 600 ? "column" : "row"}
				mt={1}
			>
				<Box width={width < 600 ? "100%" : "48%"} mr={1}>
					<Field
						component={TextField}
						type='text'
						select
						variant='filled'
						name='endsOn'
						id='endsOn'
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e.target.value === "OnDate") {
								setFieldValue("endType", "OnDate");
								setFieldValue("occurrences", null);
							} else {
								setFieldValue("endType", "AfterOccurrences");
								setFieldValue("occurrences", 1);
							}
						}}
						helperText=' '
						fullWidth
						value={values.endType}
						disabled={trueValues === 0}
					>
						<MenuItem value='OnDate'>On</MenuItem>
						<MenuItem value='AfterOccurrences'>After</MenuItem>
					</Field>
				</Box>
				<Box width={width < 600 ? "100%" : "48%"}>
					{values.endType === "OnDate" ? (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label='Ends On'
								value={
									values.repeatEndDate
										? dayjs(values.repeatEndDate, "YYYY-MM-DD")
										: null
								}
								format='MM/DD/YYYY'
								onChange={(newValue) => {
									const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
									setFieldValue("repeatEndDate", formattedDate);
								}}
								slotProps={{
									textField: {
										variant: "filled",
										fullWidth: true,
										InputLabelProps: {
											shrink: true,
										},
										// error: errors.startTime ? true : false,
										// helperText: errors.startTime ? errors.startTime : "",
									},
								}}
								disabled={trueValues === 0}
								disablePast
								minDate={dayjs(new Date(values.endTime))}
							/>
						</LocalizationProvider>
					) : (
						<Field
							component={TextField}
							type='number'
							variant='filled'
							name='occurences'
							id='occurences'
							label='Occurrences'
							fullWidth
							placeholder='Occurrences'
							value={values.occurrences}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (Number(e.target.value) > 1000) {
									setFieldValue("occurrences", 1000);
								} else {
									setFieldValue("occurrences", Number(e.target.value));
								}
							}}
							disabled={trueValues === 0}
							min={1}
							max={1000}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default RepeatShift;
