import { Box, Switch, Typography } from "@mui/material";
import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	setCrossSkillingLevelsDialog,
	setCrossSkillingPrioritiesDialog,
} from "../../../../../../../export/gat-admin";
import { ShiftBySkills } from "../../../../../../../export/gat-admin";
import { CrossSkillingLevelsTable } from "../../../../../../../export/gat-admin/cross-skilling/cross-skilling-levels-table";
import { CrossSkillingLevelsDialog } from "../../../../../../../export/gat-admin/cross-skilling/cross-skilling-levels-table/cross-skilling-levels-table-components";
import { CrossSkillingLevelsPlaceholder } from "../../../../../../../export/gat-admin/cross-skilling/cross-skilling-levels-table/cross-skilling-levels-table-components";
import { SkillLevel } from "../../../../../cross-skilling/types";
import SkillCertification from "./skill-certification";
import SkillExpiry from "./skill-expiry";
import SkillLevelJobMapping from "./skill-level-job-mapping";
import SkillPriorities from "./skill-priorities";
import SkillPriorityConfigDialog from "./skill-priority-config-dialog";
import SkillPriorityUseObsolete from "./skill-priority-use-obsolete";
import SkillPriorityWorkerApp from "./skill-priority-worker-app";

interface CrossSkillingProps {
	companySkillLevels: SkillLevel[];
	newAddOnSettings: any; // TODO: Define type
	setNewAddOnSettings: Dispatch<any>;
	updateCompanySettingsLevelStatus: string;
	companyId: string;
	entity: string;
	handleSave: () => void;
}

const CrossSkilling: React.FC<CrossSkillingProps> = ({
	companySkillLevels,
	newAddOnSettings,
	setNewAddOnSettings,
	updateCompanySettingsLevelStatus,
	companyId,
	entity,
	handleSave,
}) => {
	const dispatch = useDispatch();
	const openCrossSkillingEditDialog = (open: boolean) => {
		dispatch(setCrossSkillingLevelsDialog(open));
	};
	const openCrossSkillingLevelsDialog = useSelector(
		(state: any) => state.gatAdminConfiguration.openCrossSkillingLevelsDialog
	);
	const openCrossSkillingSkillPrioritiesDialog = useSelector(
		(state: any) =>
			state.gatAdminConfiguration.openCrossSkillingPrioritiesDialog
	);
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Cross-Skilling
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Up-skilling your worker.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newAddOnSettings.crossSkillingEnabled ?? false}
						onChange={(e) => {
							setNewAddOnSettings((pre: any) => ({
								...pre,
								crossSkillingEnabled: e.target.checked,
							}));
							if (!e.target.checked) {
								setNewAddOnSettings((pre: any) => ({
									...pre,
									shiftBySkillsEnabled: false,
								}));
							}
						}}
					/>
				</Box>
			</Box>
			{companySkillLevels && entity === "company" && (
				<>
					{newAddOnSettings.crossSkillingEnabled &&
						companySkillLevels.length !== 0 && (
							<CrossSkillingLevelsTable
								skillLevels={companySkillLevels}
								openCrossSkillingEditDialog={openCrossSkillingEditDialog}
							/>
						)}
				</>
			)}
			{companySkillLevels && entity === "company" && (
				<>
					{newAddOnSettings.crossSkillingEnabled &&
						companySkillLevels.length === 0 && (
							<CrossSkillingLevelsPlaceholder
								openCrossSkillingDialog={openCrossSkillingEditDialog}
							/>
						)}
				</>
			)}
			{!companySkillLevels && entity === "company" && (
				<>
					{newAddOnSettings.crossSkillingEnabled && (
						<CrossSkillingLevelsPlaceholder
							openCrossSkillingDialog={openCrossSkillingEditDialog}
						/>
					)}
				</>
			)}

			{newAddOnSettings.crossSkillingEnabled &&
				companySkillLevels.length !== 0 && (
					<ShiftBySkills
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
					/>
				)}
			{newAddOnSettings.crossSkillingEnabled &&
				companySkillLevels.length !== 0 && (
					<SkillCertification
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
					/>
				)}
			{newAddOnSettings.crossSkillingEnabled &&
				companySkillLevels.length !== 0 && (
					<SkillExpiry
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
					/>
				)}
			{newAddOnSettings.crossSkillingEnabled &&
				companySkillLevels.length !== 0 && (
					<SkillPriorities
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
						companyId={companyId}
					/>
				)}
			{newAddOnSettings.skillPriorityEnabled &&
				companySkillLevels.length !== 0 && (
					<SkillPriorityWorkerApp
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
					/>
				)}
			{newAddOnSettings.skillPriorityEnabled &&
				companySkillLevels.length !== 0 && (
					<SkillPriorityUseObsolete
						skillLevels={companySkillLevels}
						newAddOnSettings={newAddOnSettings}
						setNewAddOnSettings={setNewAddOnSettings}
					/>
				)}
			{companySkillLevels && entity === "company" && (
				<>
					{newAddOnSettings.crossSkillingEnabled &&
						companySkillLevels.length !== 0 && (
							<SkillLevelJobMapping
								newAddOnSettings={newAddOnSettings}
								setNewAddOnSettings={setNewAddOnSettings}
							/>
						)}
				</>
			)}
			<CrossSkillingLevelsDialog
				skillLevels={companySkillLevels}
				onClose={() => {
					dispatch(setCrossSkillingLevelsDialog(false));
				}}
				companySettings={companyId}
				updateCompanySettingsLevelStatus={updateCompanySettingsLevelStatus}
				handleSave={handleSave}
				openCrossSkillingLevelsDialog={openCrossSkillingLevelsDialog}
			/>
			<SkillPriorityConfigDialog
				openCrossSkillingSkillPrioritiesDialog={
					openCrossSkillingSkillPrioritiesDialog
				}
				onClose={() => dispatch(setCrossSkillingPrioritiesDialog(false))}
				companyId={Number(companyId)}
				handleSave={handleSave}
			/>
		</Box>
	);
};

export default CrossSkilling;
