import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { SkillRepresentationCell } from "../../../../../../export/cross-skilling";
import { getRepLevel, getRepType } from "../../../../../../export/helpers";
import Loading from "../../../../../../js/components/Loading";

const WorkerInsightDetailsPriorityTab = ({ selectedWorker }: any) => {
	const [loading, setLoading] = React.useState(true);

	const allPriorities = useSelector((state: any) => state.skills.skillPriorities);

	const allSkills = useSelector((state: any) => state.skills.masterData.skills);

	const workerProfile = useSelector(
		(state: any) => state.manageWorkers.workerProfile.profile
	);

	const workerProfileStatus = useSelector(
		(state: any) => state.manageWorkers.workerProfile.status
	);

	const workerJobs = workerProfile?.jobs || [];
	const workerOtherSkills = workerProfile?.otherSkills || [];

	const workerSkills: any = {};
	workerJobs.forEach((job: any) => {
		job.skills.forEach((skill: any) => {
			workerSkills[skill.skillId] = skill;
		});
	});

	workerOtherSkills.forEach((skill: any) => {
		workerSkills[skill.skillId] = skill;
	});

	const priorities = allPriorities.map((priority: any) => {
		try {
			const skills: any = [];
			const selectedWorkerSkillIds = selectedWorker.skillIds?.map((skill: any) => skill.id);
			allSkills.forEach((skill: any) => {
				if (
					priority.id === skill.priority.id &&
					selectedWorkerSkillIds.includes(skill.id)
				) {
					skills.push(skill);
				}
			});
			return {
				...priority,
				skills,
			};
		} catch (e) {
			return priority;
		}
	});

	useEffect(() => {
		if (workerProfileStatus === "fulfilled" && allPriorities && allSkills) {
			setLoading(false);
		}
	}, [workerProfileStatus, allPriorities, allSkills]);

	if (loading || workerProfileStatus === "pending")
		return (
			<Box>
				<Loading />
			</Box>
		);
	return (
		<Box>
			{priorities.map((priority: any) => (
				<Accordion key={priority.id}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1-content'
						id='panel1-header'
					>
						{`${priority.code} - ${priority.name} (${priority.skills.length})`}
					</AccordionSummary>
					<AccordionDetails>
						<Box>
							{priority.skills.map((skill: any) => (
								<Box display='flex' alignItems='center' key={skill.id} m={1}>
									<SkillRepresentationCell
										level={workerSkills[skill.id]?.level || 0}
										// backgroundColor={workerSkills[skill.id].backColorCode}
										// foregroundColor={workerSkills[skill.id].foreColorCode}
										value={String(workerSkills[skill.id]?.levelValue) ?? ""}
										representationType={getRepType(
											String(workerSkills[skill.id]?.levelRepCode)
										)}
										representationLevel={getRepLevel(
											workerSkills[skill.id]?.levelRepCode,
											workerSkills[skill.id]?.level
										)}
										border={1}
										backgroundWidth={"35px"}
										backgroundHeight={"35px"}
									/>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontSize: "14px",
											fontWeight: "400",
											lineHeight: "20px",
											marginLeft: "10px",
											minWidth: "200px",
										}}
									>
										{skill.name}
									</Typography>
								</Box>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};

export default WorkerInsightDetailsPriorityTab;
