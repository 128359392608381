import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React from "react";

import useCrossSkillData from "../../../../../../js/components/cross-skilling/controllers/use-cross-skill-data";
import { getRepLevel, getRepType } from "../../../../../../js/utils/helpers";
import Highlight from "../../../../Highlight";
import SkillRepresentationCell from "./column-skill-representations-cell";

interface ColumnSkillHeaderProps {
	skill: {
		name: string;
		jobId: string;
		id: string;
	};
	searchText: string;
}

const ColumnSkillHeader: React.FC<ColumnSkillHeaderProps> = ({
	skill,
	searchText,
}) => {
	const {
		allSkillLevels,
		//isJobSkillLevelMapping,
		jobSkillLevelMap,
	} = useCrossSkillData();

	const isJobSkillLevelMapping = false;
	const jobSkillLevel = jobSkillLevelMap[`${skill.jobId}-${skill.id}`];

	const level = allSkillLevels.find(
		(level: { id: number }) => level.id === jobSkillLevel
	);
	return (
		<Box
			height={isJobSkillLevelMapping ? 60 : "100%"}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "center",
					width: "100%",
					paddingTop: isJobSkillLevelMapping ? "0px" : "8px",
					paddingBottom: isJobSkillLevelMapping ? "0px" : "8px",
				}}
				maxHeight={isJobSkillLevelMapping ? 20 : "100%"}
			>
				<Tooltip title={skill.name}>
					<Typography
						sx={{ wordBreak: "break-word" }}
						whiteSpace='normal'
						style={{
							fontSize: "12px",
							fontWeight: "bold",
							padding: "4px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: "2",
							WebkitBoxOrient: "vertical",
							marginLeft: "10px",
						}}
					>
						<Highlight value={skill.name} searchText={searchText} />
					</Typography>
				</Tooltip>
			</Box>
			<Divider />
			{isJobSkillLevelMapping && (
				<Box height={20} mb={jobSkillLevel ? 1 : 3}>
					{jobSkillLevel ? (
						<Box display='flex' alignItems='center' mt={0.2}>
							<SkillRepresentationCell
								level={level?.level}
								value={String(level?.value)}
								representationType={getRepType(String(level?.repCode))}
								representationLevel={getRepLevel(level?.repCode, level?.level)}
								height={15}
								width={15}
								backgroundColor={level?.backColorCode}
								foregroundColor={level?.foreColorCode}
								backgroundWidth={"18px"}
								backgroundHeight={"18px"}
							/>
							<Typography
								variant='caption'
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									fontWeight: 400,
									padding: "4px",
								}}
							>
								{level?.name}
							</Typography>
						</Box>
					) : (
						<Typography
							variant='caption'
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								fontWeight: 400,
								padding: "4px",
							}}
						>
							N/A
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
};

export default ColumnSkillHeader;
