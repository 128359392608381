import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";

import axios from "../../../utils/api-client";
import {
  setSupervisorRateLimitError,
} from "./../../../features/user/user-slice";
import { AbsenceReport, AbsenceReportsState } from "./absence-reports-types";

const initialState: AbsenceReportsState = {
  dailyReports: [],
  monthlyReports: [],
  getMonthlyReport: {
    status: "idle",
    errorMessage: "",
  },
  getDailyReport: {
    status: "idle",
    errorMessage: "",
  },
  monthStartDate: startOfDay(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  ).toISOString(),
  monthEndDate: endOfDay(new Date()).toISOString(),
  todayStart: startOfDay(new Date()).toISOString(),
  todayEnd: endOfDay(new Date()).toISOString(),
};

// API to get Daily Absence Reports
export const getDailyReports = createAsyncThunk(
  "absence-reports/get-daily-report",
  async (
    { startDate, endDate }: { startDate: string; endDate: string },
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/workers/AbsenceReports?startDate=${startDate}&endDate=${endDate}`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response.data;
      errorResponse.status = error?.response?.status;
      try {
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);

        }
        return rejectWithValue(error?.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

// API to get Monthly Absence Reports
export const getMonthlyReports = createAsyncThunk(
  "absence-reports/get-monthly-report",
  async (
    { startDate, endDate }: { startDate: string; endDate: string },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}/workers/AbsenceReports?startDate=${startDate}&endDate=${endDate}`
      );
      return fulfillWithValue(res.data);
    } catch (error: any) {
      const errorResponse = error?.response.data;
      errorResponse.status = error?.response?.status;
      try {
        if (errorResponse.status === 429) {
          dispatch(setSupervisorRateLimitError());
          return rejectWithValue(errorResponse);
        }
        return rejectWithValue(error?.message);
      } catch (e) {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const AbsenceReportSlice = createSlice({
  name: "absenceReports",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.monthStartDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.monthEndDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get Daily Reports
    builder.addCase(getDailyReports.pending, (state) => {
      state.getDailyReport.status = "pending";
    });
    builder.addCase(getDailyReports.fulfilled, (state, action) => {
      // add Id to each report for datagrid
      state.dailyReports = action.payload.map(
        (report: AbsenceReport, index: number) => {
          return {
            id: index,
            ...report,
          };
        }
      );
      state.getDailyReport.status = "fulfilled";
    });
    builder.addCase(getDailyReports.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getDailyReport.status = "error";
      } else {
        state.getDailyReport.status = "error";
        state.getDailyReport.errorMessage =
          action.payload ?? "An error occurred";
      }
    });

    // get Monthly Reports
    builder.addCase(getMonthlyReports.pending, (state) => {
      state.getMonthlyReport.status = "pending";
    });
    builder.addCase(getMonthlyReports.fulfilled, (state, action) => {
      // add Id to each report for datagrid
      state.monthlyReports = action.payload.map(
        (report: AbsenceReport, index: number) => {
          return {
            id: index,
            ...report,
          };
        }
      );
      state.getMonthlyReport.status = "fulfilled";
    });
    builder.addCase(getMonthlyReports.rejected, (state, action: any) => {
      if (action?.payload?.status === 429) {
        state.getMonthlyReport.status = "error";
      } else {
        state.getMonthlyReport.status = "error";
        state.getMonthlyReport.errorMessage =
          action.payload ?? "An error occurred";
      }
    });
  },
});

export const { setStartDate, setEndDate } = AbsenceReportSlice.actions;

export default AbsenceReportSlice.reducer;
