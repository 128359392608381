import React from "react";

import {ColumnCell, ColumnEmployeeCell ,ColumnHeader, ColumnSkillCell, ColumnSkillHeader} from "../../../../export/cross-skilling";
import {formatSkillScore} from "../../../../ts/utils/helpers";
import { formatSkillsByJobs } from "../../../utils/helpers";

const useCrossSkillColumn = (
	filteredJobs,
	allSkills,
	allSkillLevels,
	handleSkillLevels,
	handleAddSkillInterested,
	searchText,
	getFilter,
) => {
	const allJobs = filteredJobs;
	const isJobFilter = getFilter("job") ? getFilter("job").jobs.length > 0 : false;
	const isSkillFilter = getFilter("skill") ? getFilter("skill").skills.length > 0 : false;

	const skillIds = isSkillFilter
		? getFilter("skill").skills.map((skill) => skill.id)
		: [];

	const filteredSkills = allSkills.filter((skill) =>
		skillIds.includes(skill.id)
	);

	const jobIdsSkillsArr = isJobFilter
		? []
		: isSkillFilter
		? filteredSkills
		: allSkills;

	const jobIds = isJobFilter ? getFilter("job").jobs.map((job) => job.id) : [];

	isJobFilter && formatSkillsByJobs(allJobs, jobIdsSkillsArr, jobIds);

	let col = [];

	// Add worker employee id column
	col.push({
		field: "companyEmployeeId",
		headerName: "Worker ID",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Worker ID"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.companyEmployeeId ?? "-"}
				searchText={searchText}
			/>
		),
		valueGetter: (params) => params.row.companyEmployeeId,
		order: 4,
		sortComparator: (v1, v2) => {
			const getNumericPart = (value) => {
				if (!value) return null;
				const trimmedValue = value.trim(); // Trim spaces
				const match = trimmedValue.match(/^\d+$/); // Match fully numeric values
				return match ? parseInt(trimmedValue, 10) : null;
			};

			const isBlank = (value) =>
				value === null || value === undefined || value.trim() === "";

			// Handle blank/null/undefined values
			const blank1 = isBlank(v1);
			const blank2 = isBlank(v2);

			if (blank1 && blank2) return 0; // Both are blank
			if (blank1) return 1; // Blank values should come last
			if (blank2) return -1; // Blank values should come first

			const num1 = getNumericPart(v1);
			const num2 = getNumericPart(v2);

			// First compare by numerical value
			if (num1 !== null && num2 !== null) {
				return num1 - num2;
			}

			// If one is numeric and the other is not, prioritize the numeric one
			if (num1 !== null) return -1;
			if (num2 !== null) return 1;

			// Fall back to standard string comparison
			return v1.localeCompare(v2);
		},
	});

	// Add worker job title column
	col.push({
		field: "jobTitle",
		headerName: "Job Title",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Job Title"} />,
		renderCell: (params) => (
			<ColumnCell text={params.row.jobTitle ?? "-"} searchText={searchText} />
		),
		valueGetter: (params) => params.row.jobTitle,
		order: 5,
	});
	// Add worker name column
	col.push({
		field: "name",
		headerName: "Worker Name",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Worker Name"} />,
		renderCell: (params) => (
			<ColumnEmployeeCell params={params} searchText={searchText} />
		),
        valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
        order: 6,
	});
	// Add supervisor column
	col.push({
		field: "managerName",
		headerName: "Supervisor",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Supervisor"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.managerName ?? "-"}
				searchText={searchText}
			/>
		),
		valueGetter: (params) => params.row.managerName.toLowerCase(),
		order: 7,
	});
	// Work center column
	col.push({
		field: "workCenter",
		headerName: "Work Center",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Work Center"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.workCenter ?? "-"}
				searchText={searchText}
			/>
		),
		valueGetter: (params) => params.row.workCenter,
		order: 8,
	});
	// Shift Pattern column
	col.push({
		field: "shiftPatternName",
		headerName: "Shift Pattern",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Shift Pattern"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.shiftPatternName ?? "-"}
				searchText={searchText}
			/>
		),
		valueGetter: (params) => params.row.shiftPatternName,
		order: 9,
	});
	// Add score column
	col.push({
		field: "skillScore",
		headerName: "Total Skill Score",
		width: 160,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Total Skill Score"} />,
		renderCell: (params) => (
			<ColumnCell
				text={formatSkillScore(params.row.skillScore) ?? "-"}
				searchText={searchText}
			/>
		),
		order: 10,
	});
	const skillsCol = jobIdsSkillsArr.map((skill, idx) => {
		const currentSkill = { ...skill, id: skill.skillId };
		return {
			field: isJobFilter ? skill.id + skill.jobColor : skill.id.toString(),
			headerName: skill.name,
			width: 100,
			type: "actions",
			headerAlign: "center",
			sortable: true,
			renderCell: (params) => (
				<ColumnSkillCell
					skill={isJobFilter ? currentSkill : skill}
					params={params}
					handleSkillLevels={handleSkillLevels}
					allSkillLevels={allSkillLevels}
					handleAddSkillInterested={handleAddSkillInterested}
				/>
			),
			renderHeader: () => (
				<ColumnSkillHeader
					skill={skill}
					isJobFilter={isJobFilter}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => {
				// For sorting skills
				const skillInCell = params.row.skills.find(
					(x) => x.skillId === parseInt(skill.skillId)
				);
				if (skillInCell) {
					return skillInCell.level + 2;
				} else {
					return 1;
				}
			},
			order: 11 + idx,
		};
	});
	const columns = [...col, ...skillsCol];

	return { columns };
};

export default useCrossSkillColumn;
