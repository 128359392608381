import { AlertTitle, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetSupervisorRateLimitError } from "../features/user/user-slice";

export default function GlobalSnackBar({}) {
  const data = useSelector((state) => state?.user?.supervisorRateLimitError);
  const supervisorRateLimitError = data.isErrorOccurred;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetSupervisorRateLimitError());
  };
  return (
    <Snackbar
      open={supervisorRateLimitError}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        <AlertTitle>{"Too many requests in a short duration"}</AlertTitle>
        {
          "You’ve hit the maximum request limit for now. Your limit will reset shortly. Thanks for your understanding."
        }
      </MuiAlert>
    </Snackbar>
  );
}
